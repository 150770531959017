import React, { useContext, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Swal from 'sweetalert2';
import { Checkbox, DatePicker, Input, Switch, Tooltip } from 'antd';
import MDButton from 'components/MDButton';
import { SearchOutlined } from '@ant-design/icons';
import MultiSelect from '../../components/Select/MultiSelect';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { ContentState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import moment from 'moment-timezone';
import { useNavigate } from 'react-router-dom';
import { JsonEditor } from 'react-jsondata-editor';
import 'assets/datatable-css/editorstyles.css';
import request from './Http';
import firebase from '../../config/firebase';
import { toast } from 'react-toastify';
import style from 'styled-components';
import { Context } from 'utils/context/store/Store';
import { SET_HC_BILLABLE_COST } from 'utils/context/store/Constants';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { Button } from 'devextreme-react';
import io from 'socket.io-client';

const CryptoJS = require("crypto-js");
const _ = require('lodash')
const momentC = require('moment')

export const convertToLocalDateTime = (dateTime, format = 'YYYY-MM-DD hh:mm:ss A') => {
  const tz = moment.tz.guess();
  return moment(dateTime).tz(tz).format(format);
};

export const getLocalDateTime = (format = 'YYYY-MM-DD hh:mm:ss A') => {
  return moment().format(format);
};

export const checkIfStringIsDate = (dateTime) => {
  return moment(dateTime, moment.ISO_8601, true).isValid();
};

export const getTimeDifference = (dateTime) => {
  const toDate = Date.parse(dateTime);
  return moment().diff(toDate, 'h');
};

export const hasDuplicates = (values, key) => {

  // Map the array, trimming values and filtering out null, undefined, and empty strings
  const valueArr = values
    .map(item => item[key]?.trim())
    .filter(value => value !== null && value !== undefined && value !== '');

  // Check for duplicates in the filtered array
  return valueArr.some((item, idx) => {
    return valueArr.indexOf(item) !== idx;
  });

  /*const valueArr = values.map(function (item) { return item[key]?.trim() });
  return valueArr.some(function (item, idx) {
    return valueArr.indexOf(item) !== idx
  });*/
}

export const removeKeyAndIndexFromArrayOfObjects = (data) =>
  data.map(({ key, index, ...rest }) => ({ ...rest }));

export const isJSONValid = (data) =>
  data ? (typeof data == 'object' ? data : JSON.parse(data)) : {};

export const JSONStringify = (data) => {
  return data ? (typeof data == 'object' ? JSON.stringify(data) : data) : {};
};

export const isNull = (value) => {
  if (value === null || value === undefined) return true;
  return false;
};

export const isObjectEmpty = (obj) => {
  if (obj == null || typeof obj !== 'object') return true;
  return Object.keys(obj).length === 0;
};

export const isNumeric = (num) => !isNaN(num);

export const addIndexAndKeyInNewRowObj = (obj) => {
  return {
    ...obj,
    index: uuidv4(),
    key: uuidv4()
  };
};

export const addSequenceInNewRowObj = (obj, length, segId = null) => {
  if (segId) {
    return {
      ...obj,
      sequence: length,
      masterJdSegmentId: segId,
      index: uuidv4(),
      key: uuidv4()
    };
  } else {
    return {
      ...obj,
      sequence: length,
      index: uuidv4(),
      key: uuidv4()
    };
  }
};

/**
 * @param callback
 * @param icon
 * @param text
 * @param btnText
 * fire notification dialog box and perform action on confirm
 **/
export const showDialogBox = (
  callback,
  icon = 'warning',
  btnText = '',
  title = 'Are you sure?',
  text = '',
  showCancelButton = true
) => {
  Swal.fire({
    title: title,
    text: text,
    icon: icon,
    showCancelButton: showCancelButton,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: btnText,
    heightAuto: false,
    height: '200px',
    customClass: {
      actions: icon ? '' : 'swal-actions',
      htmlContainer: icon ? 'swal-text' : 'swal-text-wo-icon',
      title: 'swal-text',
      container: '__swal__continer__ __font__family',
      confirmButton: 'swal-button __default__button__layout',
      ...(showCancelButton && { cancelButton: 'swal-button __default__button__layout' })
    }
  }).then((result) => {
    if (result.isConfirmed) {
      callback();
    }
  });
};

export const handleDeleteRequest = (
  callback,
  icon = 'warning',
  text = "You won't be able to revert this!",
  btnText = 'Yes, delete it!'
) => {
  Swal.fire({
    title: 'Are you sure?',
    text: text,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: btnText,
    heightAuto: false,
    height: '200px',
    customClass: {
      container: '__swal__continer__ __font__family',
      confirmButton: '__default__button__layout',
      cancelButton: '__default__button__layout'
    }
  }).then((result) => {
    if (result.isConfirmed) {
      callback();
    }
  });
};

export const addKeyAndIndexFieldToApprovals = (obj, length, valId) => {
  if (valId) {
    return {
      ...obj,
      sequence: length,
      workflowId: valId,
      index: uuidv4(),
      key: uuidv4()
    };
  } else {
    return {
      ...obj,
      sequence: length,
      index: uuidv4(),
      key: uuidv4()
    };
  }
};

export const addKeyAndIndexFieldToNewRowObject = (obj, length, valId) => {
  if (valId) {
    return {
      ...obj,
      sequence: length,
      functionalAccessId: valId,
      index: uuidv4(),
      key: uuidv4()
    };
  } else {
    return {
      ...obj,
      sequence: length,
      index: uuidv4(),
      key: uuidv4()
    };
  }
};

export const addKeyAndIndexFieldToArrayOfObjects = (rows) => {
  rows.forEach((row) => {
    row.index = row.sequence;
    row.key = row.id;
  });

  return rows;
};

export const addKeyAndIndexFieldToGenericArrayOfObjects = (rows) => {
  rows.forEach((row) => {
    if (row.hasOwnProperty('id')) {
      row.sequence = row.id;
      row.index = row.id;
      row.key = row.id;
    }
  });

  return rows;
};

export const setColumnsForDataTable = (columnsFromAPI, dropdownValues) => {
  columnsFromAPI = columnsFromAPI.filter((col) => col.is_visible !== false);

  columnsFromAPI.map(function (element, key) {
    if (element.is_searchable) {
      (element.filterDropdown = ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
        return (
          <div style={{ width: '100%', margin: '-1px' }}>
            <Input
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
              style={{ fontWeight: '300', width: '95%', margin: '10px' }}
            />
            <MDButton
              variant="gradient"
              color="info"
              onClick={() => {
                confirm();
              }}
              style={{
                maxWidth: '70px',
                maxHeight: '40px',
                minWidth: '70px',
                minHeight: '40px',
                margin: '10px'
              }}
            >
              Search
            </MDButton>
            <MDButton
              variant="gradient"
              color="error"
              onClick={() => {
                clearFilters();
              }}
              style={{
                maxWidth: '70px',
                maxHeight: '40px',
                minWidth: '70px',
                minHeight: '40px',
                padding: '10px'
              }}
            >
              Clear
            </MDButton>
          </div>
        );
      }),
        (element.filterIcon = () => {
          return <SearchOutlined />;
        }),
        (element.onFilter = (value, record) => {
          let sKey = null;
          let msKey = null;
          let dKey = null;

          Object.entries(record).map(([key, value]) => {
            // console.log(`${key} - ${typeof (value)}`)
            if (typeof value === 'object') {
              if (!Array.isArray(value)) {
                if (sKey === null) sKey = 'str' + key;
                record[sKey] = value && value.hasOwnProperty('label') ? value.label : '';
              } else {
                let valLabel = null;
                if (value.length > 0) {
                  value.map((val) => {
                    // console.log('v', val)
                    if (valLabel === null) {
                      // valLabel = val && val.hasOwnProperty('label') ? val.label : ""
                      valLabel = val.label;
                    } else {
                      // valLabel = valLabel + ", " + val && val.hasOwnProperty('label') ? val.label : ""
                      valLabel = valLabel + ', ' + val.label;
                      // console.log('vl', valLabel)
                    }
                  });
                }
                if (msKey === null) msKey = 'str' + key;
                // console.log('val label', valLabel)
                record[msKey] = valLabel;
              }
            }

            if (checkIfStringIsDate(value)) {
              if (dKey === null) dKey = 'str' + key;
              record[dKey] = convertToLocalDateTime(value);
            }
          });

          if (element.type === 'select') {
            return record[sKey].toLowerCase().includes(value.toLowerCase());
          } else if (element.type === 'multi-select') {
            return record[msKey].toLowerCase().includes(value.toLowerCase());
          } else if (element.type === 'toggle' || element.type === 'checkbox') {
            return record[element.dataIndex];
          } else if (element.type === 'date') {
            return record[dKey].includes(value);
          } else {
            if (record[element.dataIndex]) {
              return record[element.dataIndex].toLowerCase().includes(value.toLowerCase());
            }
          }
        });
    }

    if (element.is_sortable) {
      if (element.type === 'toggle' || element.type === 'checkbox' || element.type === 'int') {
        element.sorter = (a, b) => {
          return Number(a[element.dataIndex]) - Number(b[element.dataIndex]) ?? 1;
        };
      } else if (element.type === 'select') {
        element.sorter = (a, b) => {
          let firstObject = null;
          let secondObject = null;
          if (
            typeof a[element.dataIndex] === 'object' &&
            a[element.dataIndex] &&
            a[element.dataIndex].length > 0
          ) {
            firstObject = a[element.dataIndex][0]['id'];
            secondObject = b[element.dataIndex][0]['id'];
          } else {
            firstObject = a[element.dataIndex];
            secondObject = b[element.dataIndex];
          }
          const first = dropdownValues[element.dataIndex]
            ? dropdownValues[element.dataIndex].filter((val) => val.id === firstObject)
            : '';
          const second = dropdownValues[element.dataIndex]
            ? dropdownValues[element.dataIndex].filter((val) => val.id === secondObject)
            : '';
          if (first.length > 0 && second.length > 0)
            return first[0]['label'].localeCompare(second[0]['label']);
          else return '';
        };
      } else if (element.type === 'multi-select') {
        element.sorter = (a, b) => {
          if (
            a[element.dataIndex] &&
            a[element.dataIndex].length > 0 &&
            b[element.dataIndex] &&
            b[element.dataIndex].length > 0
          )
            return a[element.dataIndex][0]['label'].localeCompare(b[element.dataIndex][0]['label']);
          else return '';
        };
      } else if (element.type === 'string' || element.type === 'textarea') {
        element.sorter = (a, b) => {
          if (a[element.dataIndex] && b[element.dataIndex])
            return a[element.dataIndex].localeCompare(b[element.dataIndex]);
          else return '';
        };
      }
    }

    if (element.type === 'string') {
      element.render = (text, record) => {
        return <Tooltip title={text}>{text}</Tooltip>;
      };
    } else if (element.type === 'textarea') {
      element.render = (text, record) => {
        return <Tooltip title={text}>{text}</Tooltip>;
      };
    } else if (element.type === 'toggle') {
      element.render = (text, record) => {
        return <Switch disabled checked={record[element.dataIndex]} />;
      };
    } else if (element.type === 'date') {
      element.render = (text, record) => {
        return convertToLocalDateTime(record[element.dataIndex]);
      };
    } else if (element.type === 'json') {
      element.render = (text, record) => {
        return <pre>{JSON.stringify(isJSONValid(record[element.dataIndex]))}</pre>;
      };
    } else if (element.type === 'checkbox') {
      element.render = (text, record) => {
        return <Checkbox disabled checked={record[element.dataIndex]} />;
      };
    } else if (element.type === 'select') {
      element.render = (text, record) => {
        return MultiSelect(
          dropdownValues[element.dataIndex],
          record[element.dataIndex],
          true,
          false
        );
      };
    } else if (element.type === 'multi-select') {
      element.render = (text, record) => {
        return MultiSelect(
          dropdownValues[element.dataIndex],
          record[element.dataIndex],
          true,
          true
        );
      };
    } else if (element.type === 'text-editor') {
      function createMarkup(data) {
        return { __html: data };
      }

      element.render = (text, record) => {
        return (
          <Tooltip
            title={
              <p dangerouslySetInnerHTML={createMarkup(record[element.dataIndex] ?? '<p></p>')} />
            }
          >
            <p dangerouslySetInnerHTML={createMarkup(record[element.dataIndex] ?? '<p></p>')} />{' '}
          </Tooltip>
        );
      };
    }
  });

  return columnsFromAPI;
};

export const jdEditorStyle = {
  overflowY: 'auto',
  backgroud: 'white !important',
  height: '20rem',
  padding: '0px 1rem',
  cursor: 'not-allowed !important',
  opacity: '0.6 !important',
  pointerEvents: 'all !important'
};

export const editorStyle = {
  backgroud: 'white !important',
  // height: '10rem',
  padding: '0px 1rem',
  cursor: 'not-allowed !important',
  opacity: '0.6 !important',
  pointerEvents: 'all !important'
};

export const editorWrapperStyle = {
  background: 'white',
  border: '1px solid #E7E7E7'
};

export const renderEditableFieldInDataTable = (type, record, dataIndex, dropdownValues) => {
  const [color, setColor] = useState('');
  const { TextArea } = Input;
  const [{ billableCost }, dispatch] = useContext(Context);

  if (type === 'text-editor') {
    const contentBlocks = htmlToDraft(record[dataIndex] != null ? record[dataIndex] : '');
    const contentState = ContentState.createFromBlockArray(contentBlocks);
    const rawHtml = convertToRaw(contentState);
    record['displayValue'] = rawHtml;
  }

  useEffect(() => setColor(color), [color]);

  // console.log(`${type === 'select' ? dropdownValues : ""} `)
  return type === 'toggle' ? (
    <Switch
      checked={record[dataIndex] === 'true' ? true : dataIndex}
      onChange={(checked) => {
        // console.log(checked)
        if (dataIndex === 'billable') {
          if (checked) {
            dispatch({
              type: SET_HC_BILLABLE_COST,
              payload: true
            });
          } else {
            dispatch({
              type: SET_HC_BILLABLE_COST,
              payload: false
            });
          }
        }
      }}
    />
  ) : type === 'checkbox' ? (
    <Checkbox
      defaultChecked={record[dataIndex]}
      onChange={(e) => (record[dataIndex] = e.target.checked)}
    />
  ) : type === 'textarea' ? (
    <TextArea style={{ fontWeight: '300' }} />
  ) : type === 'string' ? (
    <Input style={{ fontWeight: '300' }} />
  ) : type === 'select' ? (
    MultiSelect(dropdownValues[dataIndex], record[dataIndex], false, false, dataIndex)
  ) : type === 'multi-select' ? (
    MultiSelect(dropdownValues[dataIndex], null, false, true)
  ) : type === 'text-editor' ? (
    <Editor
      handlePastedText={() => false}
      stripPastedStyles={true}
      contentState={record.displayValue}
      onEditorStateChange={(contentState) => {
        record[dataIndex] = draftToHtml(convertToRaw(contentState.getCurrentContent()));
      }}
      toolbar={{
        options: ['inline', 'list', 'textAlign'],
        inline: {
          inDropdown: false,
          options: ['bold', 'italic', 'underline']
        },
        list: {
          inDropdown: false,
          options: ['unordered', 'ordered']
        },
        textAlign: {
          inDropdown: false,
          options: ['left', 'center', 'right', 'justify']
        }
      }}
    />
  ) : type === 'json' ? (
    <div style={{ height: '400px', width: '500px', border: 'solid 1px #dddddd' }}>
      <JsonEditor
        jsonObject={JSON.stringify(isJSONValid(record[dataIndex]))}
        onChange={(output) => {
          record[dataIndex] = output;
        }}
      />
    </div>
  ) : type === 'date' ? (
    <DatePicker onChange={(date, dateString) => (record[dataIndex] = dateString)} />
  ) : (
    <Input
      type={'number'}
      disabled={dataIndex === 'cost' && !billableCost}
      style={{ fontWeight: '300' }}
    />
  );
};

export const renderEditableFieldInPopup = (type, record, dataIndex, dropdownValues) => {
  // const [color, setColor] = useState("");
  const { TextArea } = Input;
  let fieldDisabled = true;

  if (type === 'text-editor') {
    const contentBlocks = htmlToDraft(record[dataIndex] != null ? record[dataIndex] : '');
    const contentState = ContentState.createFromBlockArray(contentBlocks);
    const rawHtml = convertToRaw(contentState);
    record['displayValue'] = rawHtml;
  }

  // useEffect(() => setColor(color), [color])

  // console.log(`${type === 'select' ? dropdownValues : ""} `)
  return type === 'toggle' ? (
    <Switch
      checked={record[dataIndex] === 'true' ? true : dataIndex}
      onChange={(checked) => {
        // console.log(checked)
        if (checked) {
          // console.log(dataIndex)
          if (dataIndex === 'billable')
            // console.log(fieldDisabled)
            fieldDisabled = false;
          // console.log('aft', fieldDisabled)
        }
      }}
    />
  ) : type === 'checkbox' ? (
    <Checkbox
      defaultChecked={record[dataIndex]}
      onChange={(e) => (record[dataIndex] = e.target.checked)}
    />
  ) : type === 'textarea' ? (
    <TextArea style={{ fontWeight: '300' }} />
  ) : type === 'string' ? (
    <Input style={{ fontWeight: '300' }} />
  ) : type === 'select' ? (
    MultiSelect(dropdownValues[dataIndex], record[dataIndex], false, false, dataIndex)
  ) : type === 'multi-select' ? (
    MultiSelect(dropdownValues[dataIndex], null, false, true)
  ) : type === 'text-editor' ? (
    <Editor
      handlePastedText={() => false}
      stripPastedStyles={true}
      contentState={record.displayValue}
      onEditorStateChange={(contentState) => {
        record[dataIndex] = draftToHtml(convertToRaw(contentState.getCurrentContent()));
      }}
      toolbar={{
        options: ['inline', 'list', 'textAlign'],
        inline: {
          inDropdown: false,
          options: ['bold', 'italic', 'underline']
        },
        list: {
          inDropdown: false,
          options: ['unordered', 'ordered']
        },
        textAlign: {
          inDropdown: false,
          options: ['left', 'center', 'right', 'justify']
        }
      }}
    />
  ) : type === 'json' ? (
    <div style={{ height: '400px', width: '500px', border: 'solid 1px #dddddd' }}>
      <JsonEditor
        jsonObject={JSON.stringify(isJSONValid(record[dataIndex]))}
        onChange={(output) => {
          record[dataIndex] = output;
        }}
      />
    </div>
  ) : type === 'date' ? (
    <DatePicker onChange={(date, dateString) => (record[dataIndex] = dateString)} />
  ) : (
    <Input
      type={'number'}
      disabled={dataIndex === 'cost' && fieldDisabled}
      style={{ fontWeight: '300' }}
    />
  );
};

export const getUserInfo = () => {
  if (localStorage.getItem('user')) {
    if (process.env.NODE_ENV !== 'development' && localStorage.getItem('cleared') !== null)
      return localStorage.getItem('user') ? decryptObject(localStorage.getItem('user')) : null;
    else if (process.env.NODE_ENV !== 'development' && localStorage.getItem('cleared') === null)
      return JSON.parse(localStorage.getItem('user'));
    else return JSON.parse(localStorage.getItem('user'));
  }
};

export const hasPermission = (permissions, routeKey) => {
  const res = permissions.filter((p) => p.routeKey === routeKey);
  return res[0];
};

export const selectCustomStyles = {
  control: (styles) => ({
    ...styles,
    borderBottom: -1,
    borderTop: 0,
    borderLeft: 0,
    borderRight: 0,
    borderRadius: 0,
    fontSize: '14px'
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    display: 'none'
  }),
  menuPortal: (base) => ({
    ...base,
    zIndex: 1000,
    fontSize: '14px'
    // zIndex: 1
  }),
  menu: (base) => ({
    ...base,
    fontSize: '14px',
    zIndex: 1000
  })
};

export const getChangesInArrayOfObjects = (oldArray, newArray) => {
  let changes = [];
  if (oldArray.length === 0) {
    return false;
  }
  if (JSON.stringify(oldArray) === JSON.stringify(newArray)) {
    return false;
  }
  newArray.map((obj, i) => {
    if (JSON.stringify(obj) !== JSON.stringify(oldArray[i])) {
      changes.push(obj);
    }
  });
  return changes;
};

export const ButtonContainer = style.div`
.ant-btn,
.ant-btn-default,
.ant-btn:hover,
.ant-btn:focus,
.ant-btn:active {
  background: #16ABE0 !important;
  border: none;
  color: #fff !important;
  text-transform: uppercase;
  font-size: 0.75rem;
  font-weight: 700;
  border-radius: 0.5rem;
  line-height: 1.4;
  min-height: 2.5rem;
  padding: 0.625rem 1.5rem;
}
`;

export const workflowAccessOptions = [
  {
    id: 1,
    name: 'workflow-access',
    route: 'workflow-access',
    label: 'Workflow Access',
    value: 'Workflow Access'
  },
  {
    id: 2,
    name: 'workflow',
    route: 'workflow',
    label: 'Workflow'
  }
];

export const hcWorkflowAccessOptions = [
  {
    id: 2,
    name: 'workflow',
    route: 'headcount/workflow',
    label: 'Workflow'
  }
];

export const getPageProperties = (data, key) => {
  const pages = data.filter((d) => d.routeKey === key);
  return pages && pages.length > 0 && pages[0].page_properties
    ? isJSONValid(pages[0].page_properties)
    : null;
};

export const getTableColumns = (table, name) => {
  return table.filter((t) => t.name.toLowerCase() === name.toLowerCase())[0];
};

export const initFireBase = async () => {
  const messaging = firebase.messaging();
  messaging
    .requestPermission()
    .then(() => {
      return messaging.getToken();
    })
    .then((token) => {
      localStorage.setItem('device_token', token);
    })
    .catch((err) => {
      console.log(err);
    });
};

export const onMessageListener = async () => {
  const messaging = firebase.messaging();
  return new Promise((resolve) => {
    messaging.onMessage((payload) => {
      // console.log("onMessageListener:: ", JSON.stringify(payload));
      toast.info(`${payload.notification.title}`);
      resolve(payload);
    });
  });
};

export const deviceToken = () => localStorage.getItem('device_token');

export const callCreateUpdateDeviceTokenApi = async () => {
  // const dt = deviceToken()
  // if (dt) return await request.put('user/create-or-update-device-token', {
  //   device_token: dt,
  // })
};

export const customExportToCSV = (dataSource) => {
  let dSource = _.cloneDeep(dataSource);

  if (dSource && dSource.length > 0) {
    dSource.map((rows) => {
      Object.entries(rows).map(([key, value]) => {
        if (typeof value === 'object') {
          if (!Array.isArray(value)) {
            rows[key] = value && value.hasOwnProperty('label') ? value.label : '';
          } else {
            let valLabel = null;
            if (value.length > 0) {
              value.map((val) => {
                // console.log('v', val)
                if (valLabel === null) {
                  // valLabel = val && val.hasOwnProperty('label') ? val.label : ""
                  valLabel = val.label;
                } else {
                  // valLabel = valLabel + ", " + val && val.hasOwnProperty('label') ? val.label : ""
                  valLabel = valLabel + ', ' + val.label;
                  // console.log('vl', valLabel)
                }
              });
            }
            // console.log('val label', valLabel)
            rows[key] = valLabel;
          }
        }
      });
      // console.log(rows)
    });
  }

  return dSource;
};

export const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0
  },
  '&:before': {
    display: 'none'
  }
}));

export const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.5rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)'
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1)
  }
}));

export const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)'
}));

export const encryptObject = (data) => {
  return CryptoJS.AES.encrypt(JSON.stringify(data), 'UserData').toString();
};

export const decryptObject = (data) => {
  const bytes = CryptoJS.AES.decrypt(data, 'UserData');
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
};

/**
 * @param dataGridRef
 * @param onInitNewRow
 * Add new row programmatically to the datagrid
 **/
export function addRow(dataGridRef, onInitNewRow = null) {
  const gridInstance = dataGridRef.current?.instance;
  onInitNewRow !== null ? gridInstance?.addRow(onInitNewRow) : gridInstance?.addRow();
  gridInstance?.deselectAll();
}

export const DEButton = (props) => {
  const { stylingMode, type, text, to, state, isForIM, isBtFilled, handleOnClick, ...rest } = props;
  const navigate = useNavigate();

  const handleClick = () => {
    if (to) {
      if (handleOnClick) {
        handleOnClick();
        return;
      }
      if (isForIM) {
        localStorage.removeItem('internalEmpData');
        localStorage.setItem('internalEmpData', JSON.stringify(state));
        window.location.href = '/views/manage-headcount';
      } else {
        navigate(to, { state });
      }
    }
  };

  return (
    <div style={{ position: 'relative', display: 'inline-block' }}>
      <Button
        stylingMode={stylingMode}
        type={type}
        text={text}
        onClick={handleClick}
        to={to}
        state={state}
        {...rest}
      />
      {isBtFilled && <div style={styles.greenBand}></div>}
    </div>
  );
};

const styles = {
  greenBand: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    width: '8px',
    backgroundColor: '#4BB543',
    borderRadius: '4px 4px 4px 4px'
  }
};
/**
 * @param dataSource
 * @param verticals
 * @param masterOrgDivisionId
 * @param masterOrgEntityId
 * @param masterCountryId
 * @param masterOrgVerticalId
 * @param userLevels
 * @param LIds
 * get Levels by generic combination of DECV
 **/
export const getLevels = (
  dataSource,
  verticals,
  masterOrgDivisionId,
  masterOrgEntityId = null,
  masterCountryId = null,
  masterOrgVerticalId = null,
  userLevels = [],
  LIds = []
) => {
  const filteredData =
    dataSource && dataSource.length
      ? dataSource.filter((d) => d.masterOrgDivisionId === masterOrgDivisionId)
      : [];
  let data = [];

  // console.log('dataSource', dataSource)
  // console.log('filteredData', filteredData)
  // console.log('masterOrgDivisionId', masterOrgDivisionId)
  // console.log('masterOrgEntityId', masterOrgEntityId)
  // console.log('masterCountryId', masterCountryId)
  // console.log('masterOrgVerticalId', masterOrgVerticalId)

  if (filteredData && filteredData.length) {
    if (masterOrgEntityId) {
      data = filteredData.filter((d) => d.masterOrgEntityId === masterOrgEntityId);

      if (data && data.length <= 0) {
        data = filteredData.filter((d) => d.masterOrgEntityId === null);
        // console.log('hey there', data.length)
        // console.table(data)
      }
    } else data = filteredData.filter((d) => d.masterOrgEntityId === null);

    if (masterCountryId) {
      if (data && data.length) {
        const cData = data.filter((d) => d.masterCountryId === masterCountryId);

        if (cData && cData.length <= 0) data = data.filter((d) => d.masterCountryId === null);
        else data = cData;
      } else {
        const mcdata = filteredData.filter((d) => d.masterCountryId === masterCountryId);

        if (mcdata && mcdata.length <= 0) {
          data = filteredData.filter((d) => d.masterCountryId === null);
        } else data = mcdata;
      }
    } else data = data.filter((d) => d.masterCountryId === null);

    if (masterOrgVerticalId) {
      if (data && data.length) {
        // console.log('im here')
        const getEntities = verticals.filter(
          (v) =>
            v.masterOrgVerticalId === masterOrgVerticalId &&
            v.masterOrgDivisionId === masterOrgDivisionId
        );
        const uEntities =
          getEntities && getEntities.length
            ? [...new Map(getEntities.map((item) => [item['masterOrgEntityId'], item])).values()]
            : [];
        const uEId = _.map(uEntities, 'masterOrgEntityId');
        // console.table(uEId)
        const cData = data.filter((d) => d.masterOrgVerticalId === masterOrgVerticalId);
        // console.log('cDa')
        // console.table(cData)

        if (cData && cData.length <= 0) {
          // console.log('this is ', uEId.length)
          if (uEId.length && masterOrgEntityId === null) {
            // console.log('uEid filter')
            // console.table(filteredData.filter(d => d.masterOrgVerticalId === null && uEId.includes(d.masterOrgEntityId)))
            data = filteredData.filter(
              (d) => d.masterOrgVerticalId === null && uEId.includes(d.masterOrgEntityId)
            );
          } else data = data.filter((d) => d.masterOrgVerticalId === null);
        } else data = cData;
      } else {
        const movdata = filteredData.filter((d) => d.masterOrgVerticalId === masterOrgVerticalId);

        // console.log('movdata',movdata)
        // console.log('movdata',masterOrgVerticalId)

        if (movdata && movdata.length <= 0) {
          data = filteredData.filter((d) => d.masterOrgVerticalId === null);
        } else data = movdata;
      }
    } else {
      if (masterOrgEntityId === null) data = filteredData;
      else data = data;
    }
  }

  const finalData = data.length ? data : filteredData && filteredData.length ? filteredData : [];

  // console.log('final Da', finalData.length)
  // console.log([...new Map(finalData.map(item => [item['id'], item])).values()])

  if (finalData?.length && userLevels.length && LIds.length) {
    const uLevels = finalData.filter((a) =>
      userLevels.includes((u) => u.oId === a.oId && u.masterOrgDivisionId === masterOrgDivisionId)
    );
    const fLevels = userLevels
      .filter((ul) => ul.masterOrgDivisionId === masterOrgDivisionId)
      ?.map((fd) => fd.id);
    const fullFinalData = finalData.filter((ul) => fLevels.includes(ul.id));
    return [...new Map(fullFinalData.map((item) => [item['id'], item])).values()];
  } else return [...new Map(finalData.map((item) => [item['id'], item])).values()];
};

export function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

export function cloneHC(ids = [], dataSource) {
  return dataSource && dataSource.length ? dataSource.filter((d) => ids.includes(d.id)) : [];
}

export function isDateRangeValid(startDate, endDate, joiningDate) {
  const start = momentC(startDate).format('Y-MM-DD');
  const end = momentC(endDate).format('Y-MM-DD');
  const join = momentC(joiningDate).format('Y-MM-DD');
  // Compare the dates
  return start <= join && end <= join;
}

export async function uploadFile(url, formData) {
  try {
    const res = await request.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
      // responseType: 'blob',
    });

    return res;
  } catch (error) {
    console.error('Error uploading file:', error);
    throw error;
  }
}

export function handleUploadLogs(logs, fileName) {
  if (logs) {
    const blob = convertBase64ToBlob(logs);
    saveAs(blob, fileName);
  }
}

export function convertBase64ToBlob(base64String) {
  const byteCharacters = atob(base64String);
  const byteArray = new Uint8Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i++) {
    byteArray[i] = byteCharacters.charCodeAt(i);
  }

  const blob = new Blob([byteArray.buffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  });
  return blob;
}

export function handleUploadResult(uploadData) {
  const { message, totalRecords, inserted, updated, errors } = uploadData;

  const toastContent = (
    <div>
      {message}
      <br />
      Total Records: {totalRecords}
      <br />
      Inserted: {inserted}
      <br />
      Updated: {updated}
      <br />
      Errors: {errors}
    </div>
  );

  toast.success(toastContent);
}

/**
 * This function fills and expands 'levels' based on 'verticals'.
 *
 * @param {Array} levels - The original levels to be filled and expanded.
 * @param {Array} vert - Verticals used to fill and expand the levels.
 * @return {Array} The filled and expanded levels.
 */
export function fillAndExpandLevels(levels, vert) {
  // Create new array to hold the filled and expanded levels.
  let filledExpandedLevels = [];

  // Temp array used in processing.
  let arr = [];

  // Map verticals for efficient lookup and removal.
  let vertMap = new Map(vert.map((v) => [v.verticalKey, v]));

  // Iterate over each level.
  for (let level of levels) {
    // Destructure properties from the level.
    const {
      id: masterLevelId,
      masterOrgEntityId,
      masterOrgDivisionId,
      masterOrgVerticalId
    } = level;

    // Iterate over each vertical.
    for (let vertical of vert) {
      // If the vertical and level share the same division.
      if (vertical.masterOrgDivisionId === masterOrgDivisionId) {
        // Different conditions to determine how to fill and expand level.
        // Pushes new levels to the filledExpandedLevels array and removes used verticals from vertMap.
        // The same logic is applied in the three conditions with slight differences in the new level properties.

        // Create new level based on condition and push into filledExpandedLevels array.
        if (
          masterOrgVerticalId === vertical.id &&
          masterOrgVerticalId &&
          masterOrgEntityId !== vertical.masterOrgEntityId
        ) {
          let newLevel = {
            ...level,
            masterLevelId,
            masterOrgEntityId: vertical.masterOrgEntityId,
            masterOrgVerticalId: vertical.id,
            verticalKey: vertical.verticalKey
          };
          filledExpandedLevels.push(newLevel);
          vertMap.delete(vertical.verticalKey);
        }
        // Another condition for creating new level and pushing into filledExpandedLevels array.
        else if (
          !masterOrgVerticalId &&
          masterOrgEntityId &&
          masterOrgEntityId === vertical.masterOrgEntityId
        ) {
          let newLevel = {
            ...level,
            masterLevelId,
            masterOrgVerticalId: vertical.id,
            verticalKey: vertical.verticalKey
          };
          filledExpandedLevels.push(newLevel);
          vertMap.delete(vertical.verticalKey);
        }
        // Another condition for creating new level and pushing into filledExpandedLevels array.
        else if (
          masterOrgVerticalId &&
          masterOrgVerticalId === vertical.id &&
          masterOrgEntityId &&
          masterOrgEntityId === vertical.masterOrgEntityId
        ) {
          let newLevel = {
            ...level,
            masterLevelId,
            verticalKey: vertical.verticalKey
          };
          filledExpandedLevels.push(newLevel);
          vertMap.delete(vertical.verticalKey);
        }
      }
    }

    // Iterate over each remaining vertical in vertMap to create new level and push into arr.
    for (let [id, vertical] of vertMap) {
      if (vertical.masterOrgDivisionId === masterOrgDivisionId) {
        if (
          !masterOrgEntityId &&
          !masterOrgVerticalId &&
          filledExpandedLevels.some((e) => e.verticalKey !== vertical.verticalKey)
        ) {
          let newLevel = {
            ...level,
            masterLevelId,
            masterOrgEntityId: vertical.masterOrgEntityId,
            masterOrgVerticalId: vertical.id
          };
          arr.push(newLevel);
        }
      }
    }
  }

  // Unique set for tracking unique levels.
  let uniqueLevels = new Set();

  // Final output array.
  let uniqueFilledExpandedLevels = [];

  // Combine filledExpandedLevels and arr.
  const combinedArray = [...filledExpandedLevels, ...arr];

  //Eliminate duplicates from combinedArray and push into uniqueFilledExpandedLevels.
  for (let level of combinedArray) {
    let key = `${level.masterLevelId}-${level.masterOrgEntityId}-${level.masterOrgVerticalId}-${level.masterOrgDivisionId}`;

    if (!uniqueLevels.has(key)) {
      uniqueLevels.add(key);
      uniqueFilledExpandedLevels.push(level);
    }
  }

  // Return the final filled and expanded levels with no duplicates.
  return uniqueFilledExpandedLevels;
}

export function measurePageLoadTime() {
  const observer = new PerformanceObserver((list) => {
    const entries = list.getEntries();
    entries.forEach((entry) => {
      if (entry.entryType === 'navigation') {
        // console.log("page load time", entry.loadEventEnd - entry.startTime)
      }
    });
  });

  // Observe buffered performance entries of type "navigation"
  observer.observe({ type: 'navigation', buffered: true });
}

// Function to check if required fields are filled in an object
export const areRequiredFieldsFilled = (arrayOfObjects, obj, returnToast = true) => {
  // Filter out the required fields
  const requiredFields = arrayOfObjects.filter(
    (field) => field.required && field.editable && field.is_form_visible
  );
  // Check if all required fields in obj are filled
  const unfilledFields = requiredFields.filter((field) => {
    if (
      obj?.masterJobTypeId === 1 &&
      (field.dataIndex === 'hireDate' || field.dataIndex === 'masterManagementTypeId')
    ) {
      // Check for 'hireDate' when masterJobTypeId is 1
      return obj[field.dataIndex] === null || obj[field.dataIndex] === undefined;
    } else if (
      obj?.masterJobTypeId === 2 &&
      (field.dataIndex === 'startDate' ||
        field.dataIndex === 'endDate' ||
        field.dataIndex === 'masterGigId')
    ) {
      // Check for 'startDate', 'endDate', and 'masterGigId' when masterJobTypeId is 2
      return obj[field.dataIndex] === null || obj[field.dataIndex] === undefined;
    } else {
      if (
        !['masterManagementTypeId', 'startDate', 'endDate', 'masterGigId', 'hireDate'].includes(
          field.dataIndex
        )
      ) {
        // Default condition for other fields
        return obj[field.dataIndex] === null || obj[field.dataIndex] === undefined;
      }
    }
  });

  if (unfilledFields.length === 0) return true;
  else {
    if (returnToast) {
      const unfilledFieldNames = unfilledFields.map((field) => field.title);
      const message = `Required fields not filled: ${unfilledFieldNames.join(', ')}`;
      toast.error(message);
      return false;
    } else return false;
  }
};

export function sanitizeInput(input) {
  const regex = /[<>]|(\b\(\))|(rm|cp|mv|mkdir|rm\s+-rf\s+\/)/;

  const shellCommands = [
    '\\b(ls|cd|rm|mv|cp|mkdir|rmdir|chmod|chown|cat|echo|sudo|grep|wget|curl|ssh|scp)\\b',
    '[|;`><]', // Special characters used in shell commands, excluding &
    '\\$\\(', // for command substitution
    '\\$\\?', // for exit status
    '\\$\\{[^}]+\\}', // for variable expansion
    '\\b\\d+\\s*\\&\\s*\\d+\\b' // for potential harmful usage of &
  ];

  const shellPattern = new RegExp(shellCommands.join('|'), 'i');

  // Check if the input matches the regular expression
  if (!shellPattern.test(input)) {
    // Input is safe, return it unchanged
    return true;
  } else {
    // Input contains potentially harmful characters, return false
    return false;
  }
}

export function createSanitizeAsyncRule(message) {
  return {
    message,
    validationCallback: async (e) => {
      const valid = sanitizeInput(e.value);
      if (valid) {
        return true; // Input is valid
      } else {
        return false; // Input is not valid
      }
    }
  };
}

export async function generateFileData(
  data,
  transactionType = 'hc_transaction',
  key = 'attachment'
) {
  let obj = [];
  let files = [];
  for (const d of data) {
    if (d && d.hasOwnProperty(key)) {
      let currentObj = obj.find((item) => item.id === (d?.isDraft ? d?.fileId ?? d.id : d.id));

      if (!currentObj) {
        currentObj = {
          id: key === 'approver_attachments' ? d?.fileId : d?.isDraft ? d?.fileId ?? d.id : d.id,
          fileName: []
        };
        obj.push(currentObj);
      }

      for (const a of d[key]) {
        if (a instanceof File) {
          currentObj.fileName.push(a.name);
          currentObj['transactionType'] = transactionType;
          files.push(a);
        }
      }
    }
  }

  return { fileDetails: obj, attachments: files };
}

export const reactSelectCustomPlaceHolderJD = (text) => (
  <>
    {text}
    <span style={{ color: 'red' }}>*</span>
  </>
);

export const convertImageToBase64 = (blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      resolve(reader.result);
    };
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};

export function getOSAndBrowserInfo() {
  const userAgent = navigator.userAgent;
  let osName = '';
  let browserName = '';
  let browserVersion = '';

  // Check for Windows
  if (userAgent.indexOf('Win') !== -1) {
    osName = 'Windows';
  }
  // Check for macOS
  else if (userAgent.indexOf('Mac') !== -1) {
    osName = 'macOS';
  }
  // Check for Linux
  else if (userAgent.indexOf('Linux') !== -1) {
    osName = 'Linux';
  }
  // Add more OS checks as needed

  // Check for Edge (Chromium-based)
  if (userAgent.indexOf('Edg') !== -1) {
    browserName = 'Microsoft Edge (Chromium)';
    const match = /Edg\/([\d.]+)/.exec(userAgent);
    if (match) {
      browserVersion = match[1];
    }
  }
  // Check for Chrome
  else if (userAgent.indexOf('Chrome') !== -1) {
    browserName = 'Google Chrome';
    const match = /Chrome\/([\d.]+)/.exec(userAgent);
    if (match) {
      browserVersion = match[1];
    }
  }
  // Check for Firefox
  else if (userAgent.indexOf('Firefox') !== -1) {
    browserName = 'Mozilla Firefox';
    const match = /Firefox\/([\d.]+)/.exec(userAgent);
    if (match) {
      browserVersion = match[1];
    }
  }
  // Add more browser checks as needed

  return `${browserName} (${browserVersion}) - ${osName}`;
}

export const getObjectDifferences = (obj1, obj2) => {
  const changes = {};

  const compare = (value1, value2, key) => {
    if (!_.isEqual(value1, value2)) {
      if (_.isArray(value1) && _.isArray(value2)) {
        // Compare arrays by checking each element
        if (!_.isEqual(value1, value2)) {
          changes[key] = value1;
        }
      } else if (
        _.isObject(value1) &&
        _.isObject(value2) &&
        !_.isDate(value1) &&
        !_.isDate(value2)
      ) {
        // Recursively compare nested objects
        const subChanges = getObjectDifferences(value1, value2);
        if (!_.isEmpty(subChanges)) {
          changes[key] = subChanges;
        }
      } else {
        // If they are different, save the value from obj1
        changes[key] = value1;
      }
    }
  };

  _.forEach(obj1, (value, key) => {
    compare(value, _.get(obj2, key), key);
  });

  return changes;
};

export const getAuditMetricsObject = async () => {
  const currentUrl = window.location.href;
  const userAgent = navigator.userAgent;
  const viewportSize = {
    width: window.innerWidth,
    height: window.innerHeight
  };
  const screenResolution = {
    width: screen.width,
    height: screen.height
  };
  const language = navigator.language;
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const networkInfo = navigator.connection || {};
  const memoryUsage = performance.memory || {};

  return {
    url: currentUrl,
    ipAddress: localStorage.getItem('ipAddress') ?? '',
    displayInfo: {
      viewportSize,
      screenResolution
    },
    clientInfo: {
      userAgent,
      language,
      timeZone
    },
    systemInfo: {
      networkInfo: {
        type: networkInfo.effectiveType,
        downlink: networkInfo.downlink,
        rtt: networkInfo.rtt
      },
      memoryUsage: {
        jsHeapSizeLimit: memoryUsage.jsHeapSizeLimit,
        totalJSHeapSize: memoryUsage.totalJSHeapSize,
        usedJSHeapSize: memoryUsage.usedJSHeapSize
      },
      eventTimestamp: new Date().toISOString() // The time the event occurred
    }
  };
};

export const fetchIpAddress = async () => {
  try {
    const response = await fetch('https://api.ipify.org?format=json');
    const data = await response.json();
    if (data) localStorage.setItem('ipAddress', data.ip);
  } catch (error) {
    console.error('Failed to fetch IP address:', error);
  }
};

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function checkIfValueChanged(originalFormData, dataField, value, callback) {
  const originalValue = originalFormData.current[dataField];
  console.log('Original Value:', originalValue);
  console.log('Current Value:', value);

  // Compare based on type
  const hasValueChanged = (original, current) => {
    // If both are arrays
    if (Array.isArray(original) && Array.isArray(current)) {
      // Compare array contents (length and values)
      if (original.length !== current.length) return true;
      if (original.length === current.length && current?.filter(c => !original.includes(c))?.length) return true;
      if (original.length === current.length && current?.filter(c => original.includes(c))?.length) return false;
      // Check if every element is the same (order matters)
      // return !original.every((val, index) => val === current[index]);
    }

    // If it's a File or FileList (handle file inputs)
    if (isFile(original) && isFile(current)) {
      return original.name !== current.name || original.size !== current.size || original.type !== current.type;
    }

    if (isFileList(original) && isFileList(current)) {
      if (original.length !== current.length) return true;
      for (let i = 0; i < original.length; i++) {
        if (
          original[i].name !== current[i].name ||
          original[i].size !== current[i].size ||
          original[i].type !== current[i].type
        ) {
          return true;
        }
      }
      return false;
    }


    if (original === null) return true;
    // If it's a number or string, perform a direct comparison
    if (typeof original === "number" || typeof original === "string") return original !== current;

    // If it's a file input (for single and multiple files)
    if (original instanceof File && current instanceof File) {
      return original.name !== current.name || original.size !== current.size || original.type !== current.type;
    }

    if (original instanceof FileList && current instanceof FileList) {
      if (original.length !== current.length) return true;
      for (let i = 0; i < original.length; i++) {
        if (
          original[i].name !== current[i].name ||
          original[i].size !== current[i].size ||
          original[i].type !== current[i].type
        ) {
          return true;
        }
      }
      return false;
    }

    // Fallback to strict equality for other types (e.g., boolean)
    return original !== current;
  };

  const isFile = (value) => value && typeof value.name === 'string' && typeof value.size === 'number';
  const isFileList = (value) => value && typeof value.length === 'number' && typeof value[0] === 'object' && isFile(value[0]);

  if (!hasValueChanged(originalValue, value))
  {
    // If value has not changed, reset the border class
    callback(prevState => {
      return { ...prevState, [dataField]: "" };
    });
  }
  else {
    // If value has changed, set the border class to green
    callback(prevState => {
      return { ...prevState, [dataField]: "green-border" };
    });
  }
}

export function convertToTitleCaseWithoutSeparators(input) {
  // Check if there is a separator
  if (!input.includes('_') && !input.includes('-')) return input; // Return the original string if no separator is found
  // Remove separators, split into words, and format to title case
  let words = input.replace(/[_-]/g, ' ').toLowerCase().split(' ');
  return words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
}
