import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Loadable from 'react-loadable';
import BaseLayOut from '../../../components/Layout/BaseLayOut';
import { Context } from '../../../utils/context/store/Store';
import usePagePermissions from '../../../utils/hooks/usePagePermissions';
import useManageHeadCounts from '../../../utils/hooks/useManageHeadCounts';
import useDataAccess from '../../../utils/hooks/useDataAccess';
import useHcRequestType from '../../../utils/hooks/useHcRequestType';
import useColumnPreferences from '../../../utils/hooks/useColumnPreferences';
import { getUserInfo, a11yProps } from '../../../utils/services/Helpers';
import {
  SET_GROUPS,
  SET_All_HEADCOUNTS,
  SET_IS_LOADING,
  SET_COLUMN_PREFERENCES
} from '../../../utils/context/store/Constants';
import Tabs, { Item } from 'devextreme-react/tabs';
import { Switch } from 'devextreme-react/switch';
import MDBox from '../../../components/MDBox';
// import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Card from '@mui/material/Card';
import TabPanel from '../../../components/TabPanel';
import loadingGif from 'assets/images/gifs/loading.gif';
import { Image } from 'antd';
import Skelton from '../../../components/Skelton/defaultSkelton';
const AllHcDataGrid = Loadable({
  loader: () => import('./Components/AllHcDataGrid'),
  loading: () => <Skelton />
});
const GroupsDataGrid = Loadable({
  loader: () => import('../../../components/Datagrid/GroupsDataGrid'),
  loading: () => <Skelton />
});
const ColumnPreferences = Loadable({
  loader: () => import('../../../components/ColumnPreferences'),
  loading: () => <Skelton />
});
const BlackSectionController = Loadable({
  loader: () => import('../../../components/BlackSectionController'),
  loading: () => <Skelton />
});
import ApprovedHCModal from './Components/ApprovedHCModal';
import './all-hc.scss';

export default function ManageHeadCounts({ routeKey, pageName }) {
  const [{ groups, allHeadcounts, isLoading, dataAccess }, dispatch] = useContext(Context);
  const { getPermissionsForPage } = usePagePermissions(routeKey);
  const permissions = getPermissionsForPage();
  const { getAllGroups, getAllHeadCounts, hasMore } = useManageHeadCounts(routeKey);
  const { getUserDataAccessByModule } = useDataAccess(routeKey);
  const { getHcRequestType, modalPermissions } = useHcRequestType(routeKey);
  const {
    fetchColumnPreferences,
    setColumnPreferencesData,
    checkColumnPreferencesAndUpdateTableColumns,
    storeColumnPreferences,
    setColumnPreferences
  } = useColumnPreferences();

  const [isColumnsPreferenceUpdated, setIsColumnsPreferenceUpdated] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [pageView, setPageView] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [allHcDs, setAllHcDs] = useState([]);
  const [individualOrGroupePageView, setIndividualOrGroupePageView] = useState(false);
  const [isClearFilters, setIsClearFilters] = useState(false);
  const [requestIdFilter, setRequestIdToFilter] = useState(null);
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const navigate = useNavigate();
  const search = useLocation().search;
  const rId = new URLSearchParams(search).get('rId') ?? null;
  const dgId = new URLSearchParams(search).get('dg') ?? null;
  const transactionType = new URLSearchParams(search).get('transactionType') ?? null;
  // TODO if I set the value and component re-renders then it is causing issues
  // if (rId && transactionType) {
  //   localStorage.setItem('rId', rId);
  //   localStorage.setItem('transactionType', transactionType);
  // }

  useEffect(() => {
    dispatch({ type: SET_GROUPS, payload: {} });
    /*// TODO commented because it would always go to headcount view tab on every component mount
    if ((rId !== null || rId !== undefined) && (transactionType !== null || transactionType !== undefined)) {
      setTabValue(transactionType === "hc_transaction" ? 0 : 1)
      setRequestIdToFilter(parseInt(rId))
      localStorage.removeItem('rId');
      localStorage.removeItem('dgId');
      localStorage.removeItem('transactionType');
    }*/

    // cleanup on unmount
    return () => {
      console.log('-------------unmount---component---manage---hcs----------------');
      setIsColumnsPreferenceUpdated(false);
      dispatch({ type: SET_GROUPS, payload: {} });
      dispatch({ type: SET_All_HEADCOUNTS, payload: {} });
      dispatch({ type: SET_COLUMN_PREFERENCES, payload: [] });
    };
  }, []);

  useEffect(() => {
    try {
      const fetchData = async (transactionType = null) => {
        dispatch({ type: SET_IS_LOADING, payload: true });

        try {
          await Promise.all([
            getAllHeadCounts(transactionType, true),
            getUserDataAccessByModule(getUserInfo().id, 3, true),
            getHcRequestType(true)
          ]);
        } catch (error) {
          console.error('Error fetching data:', error);
        } finally {
          dispatch({ type: SET_IS_LOADING, payload: false });
        }
      };
      const getColumnPreferences = async (pageName) => {
        await fetchColumnPreferences(routeKey, pageName, 3);
      };

      setSelectedStatuses([]);

      if (
        rId !== null &&
        rId !== 'null' &&
        rId !== undefined &&
        transactionType !== null &&
        transactionType !== 'null' &&
        transactionType !== undefined
      ) {
        setTabValue(transactionType === 'hc_transaction' ? 0 : 1);
        setRequestIdToFilter(parseInt(rId));
        fetchData(transactionType);
      } else if (
        transactionType !== null &&
        transactionType !== 'null' &&
        transactionType !== undefined
      ) {
        setTabValue(transactionType === 'hc_transaction' ? 0 : 1);
        fetchData(transactionType);
      } else {
        if (!individualOrGroupePageView && !tabValue) {
          getColumnPreferences(pageName);
          fetchData('hc_transaction');
        } else if (!individualOrGroupePageView && tabValue) {
          getColumnPreferences(pageName);
          fetchData('transaction_leaver');
        } else if (individualOrGroupePageView) {
          getColumnPreferences('manage-hcs-group');
          getAllGroups();
        }
      }
    } finally {
      // on tab change make it true so that column preference re-check triggered!
      if (isColumnsPreferenceUpdated) {
        setIsColumnsPreferenceUpdated(false);
      }
    }
  }, [tabValue, individualOrGroupePageView]);

  useEffect(() => {
    setDataSource(groups?.rows);
  }, [groups]);

  useEffect(() => {
    setAllHcDs(allHeadcounts?.rows);
  }, [allHeadcounts]);

  // TODO this helps to filter the data table, currently not visible on new UI
  // once design is ready and have managed then will reuse it, meanwhile useless.
  useEffect(() => {
    let data = allHeadcounts?.rows;

    if (selectedStatuses.length) {
      data = filterByActiveStatus(selectedStatuses, data);
    }

    setAllHcDs(data);
  }, [selectedStatuses, allHeadcounts]);

  useEffect(() => {
    const data = allHeadcounts?.columns ? allHeadcounts : groups?.columns ? groups : null;

    if (data && !isColumnsPreferenceUpdated) {
      const type = data === allHeadcounts ? 'manage-hcs' : 'manage-hcs-group';
      checkColumnPreferencesAndUpdateTableColumns(data, type);
      setIsColumnsPreferenceUpdated(true);
    }
  }, [isColumnsPreferenceUpdated, allHeadcounts, groups]);

  const handleTabChange = useCallback(
    (e) => {
      if (e.event !== undefined) {
        const newValue = e.itemIndex;

        setTabValue(newValue);

        if (!individualOrGroupePageView && (newValue !== 1 || newValue !== 0)) {
          setRequestIdToFilter(null);
        }
        if (rId) {
          navigate(`/views/manage-headcounts${dgId !== null ? `?dg=${dgId}` : ''}`, {
            replace: true
          });
        }
      }
    },
    [tabValue]
  );

  const handleLegendClicked = (filter) => {
    if (selectedStatuses.includes(filter.value)) {
      setSelectedStatuses((prevValue) => {
        return prevValue.filter((e) => e !== filter.value);
      });
    } else {
      setSelectedStatuses((prevValue) => {
        return [...prevValue, filter.value];
      });
    }
  };

  const filterByActiveStatus = (activeStatuses, arr) => {
    return arr && arr.length && arr?.filter((e) => activeStatuses?.includes(e.filterStatus));
  };

  const switchHandler = useCallback(
    (e) => {
      if (e.event !== undefined) {
        setColumnPreferences([]);
        setTabValue(0);
        setIndividualOrGroupePageView(e.value);
      }
    },
    [individualOrGroupePageView]
  );

  const handleActionButtonText = useCallback(() => {
    navigate('/views/manage-headcount');
  }, []);

  const updateDataGridTableColumns = (columnsList) => {
    setIsColumnsPreferenceUpdated(false);
    storeColumnPreferences(routeKey, pageName, columnsList, 3);
  };

  const handleClearDataGridColumnFilters = () => {
    setIsClearFilters(true);
  };

  return (
    // <BaseLayOut pageTitle={pageName === "manage-hc" ? "manage-Headcount" : "Manage Headcounts"} isForHc={true} pageView={pageView} setPageView={setPageView} setTabValue={setTabValue}>
    <div className="__body__section __manage__bulk__headcounts__">
      <div className="__manage__headcounts__container__">
        <BlackSectionController>
          <div className="__intro__section__title__description__">
            <h1 className="__primary__color">Manage Headcounts</h1>
            <ColumnPreferences
              actionButtonWidth={150}
              actionButtonText="Create Headcount"
              handleActionButtonText={handleActionButtonText}
              updateDataGridTableColumns={updateDataGridTableColumns}
              handleClearDataGridColumnFilters={handleClearDataGridColumnFilters}
              setColumnPreferences={setColumnPreferences}
            />
          </div>
        </BlackSectionController>
      </div>
      <div className="__manage__headcounts__content__section__">
        <div className="__manage__headcounts__content__row__">
          <div className="__tabs__section__">
            <div className="__toggle__section__ __font__family__regular">
              <h6 className="__mb__0">Individual</h6>
              <Switch
                className="__toggle__btn__"
                switchedOnText=""
                switchedOffText=""
                width={50}
                value={individualOrGroupePageView}
                onValueChanged={switchHandler}
              />
              <h6 className="__mb__0">Groupe View</h6>
            </div>
            <Tabs
              className="__tabs__content__"
              width={400}
              selectedIndex={tabValue}
              onItemClick={handleTabChange}
              aria-label="tabs"
            >
              {individualOrGroupePageView && (
                <Item
                  render={() => (
                    <div className="__font__family__regular __left__tab__ __right__tab__">
                      Request View
                    </div>
                  )}
                />
              )}
              {!individualOrGroupePageView && (
                <Item
                  render={() => (
                    <div className="__font__family__regular __left__tab__">Headcount View</div>
                  )}
                />
              )}
              {!individualOrGroupePageView && (
                <Item
                  render={() => (
                    <div className="__font__family__regular __right__tab__">Leaver View</div>
                  )}
                />
              )}
              {/* {modalPermissions?.can_view ? (
                <>
                  <div
                    style={{
                      position: 'absolute',
                      right: 0,
                      transform: 'translateY(-50%)',
                      top: '50%'
                    }}
                  >
                    <ApprovedHCModal permissions={modalPermissions} />
                  </div>
                  <br />
                </>
              ) : null} */}
            </Tabs>
          </div>
          {individualOrGroupePageView && tabValue >= 0 && (
            <GroupsDataGrid
              rows={dataSource}
              columns={groups?.columns ?? []}
              routeKey={routeKey}
              permissions={permissions}
              isLoading={isLoading}
              hasMore={hasMore}
              setTab={setTabValue}
              setrIdToFilter={setRequestIdToFilter}
              setPage={setIndividualOrGroupePageView}
              fetchDataFromApi={getAllGroups}
              setIsClearFilters={setIsClearFilters}
              isClearFilters={isClearFilters}
            />
          )}
          {!individualOrGroupePageView && tabValue >= 0 && (
            <AllHcDataGrid
              dataAccess={dataAccess}
              transactionType={`${tabValue === 0 ? `hc_transaction` : `transaction_leaver`}`}
              setrIdToFilter={setRequestIdToFilter}
              requestIdFilter={requestIdFilter}
              rows={
                requestIdFilter !== null && allHcDs?.length
                  ? allHcDs?.filter((l) => l?.transactionHeadcountGroupId === requestIdFilter)
                  : allHcDs
              }
              columns={allHeadcounts?.columns ?? []}
              dropDownData={allHeadcounts?.dropdownValues ?? {}}
              leaversColumns={allHeadcounts?.leaversColumns ?? {}}
              fields={allHeadcounts?.fields ?? {}}
              routeKey={routeKey}
              permissions={permissions}
              isLoading={isLoading}
              setIsClearFilters={setIsClearFilters}
              isClearFilters={isClearFilters}
              apiCallBack={async () => {
                await getAllHeadCounts(
                  `${tabValue === 0 ? `hc_transaction` : `transaction_leaver`}`
                );
                await getUserDataAccessByModule(getUserInfo().id, 3);
                await getHcRequestType();
              }}
            />
          )}
          {/* TODO remove code once testing okay */}
          {/* <TabPanel value={tabValue} index={pageView ? 0 : null}>
            <GroupsDataGrid
              rows={dataSource}
              columns={groups && groups.columns ? groups.columns : []}
              routeKey={routeKey}
              permissions={permissions}
              isLoading={isLoading}
              hasMore={hasMore}
              setTab={setTabValue}
              setrIdToFilter={setRequestIdToFilter}
              setPage={setPageView}
              fetchDataFromApi={getAllGroups}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={!pageView ? 0 : null}>
            <>
              <div className="lights-legend__container">
                {allHeadcounts?.allHcFilters?.map((filter) => (
                  <div
                    key={filter.value}
                    onClick={() => {
                      handleLegendClicked(filter);
                    }}
                    className={`lights-legend__item ${
                      selectedStatuses?.includes(filter.value)
                        ? 'lights-legend__item--active'
                        : ''
                    }`}
                  >
                    <div
                      className="lights-legend__color"
                      style={{
                        backgroundColor: filter?.color ? filter.color : 'transparent'
                      }}
                    ></div>
                    {filter?.value}
                  </div>
                ))}
              </div>
              <AllHcDataGrid
                dataAccess={dataAccess}
                transactionType={'hc_transaction'}
                setrIdToFilter={setRequestIdToFilter}
                requestIdFilter={requestIdFilter}
                rows={
                  requestIdFilter !== null && allHcDs?.length
                    ? allHcDs?.filter((l) => l?.transactionHeadcountGroupId === requestIdFilter)
                    : allHcDs
                }
                columns={allHeadcounts && allHeadcounts.columns ? allHeadcounts.columns : []}
                dropDownData={
                  allHeadcounts && allHeadcounts.dropdownValues ? allHeadcounts.dropdownValues : {}
                }
                leaversColumns={
                  allHeadcounts && allHeadcounts.leaversColumns ? allHeadcounts.leaversColumns : {}
                }
                fields={allHeadcounts && allHeadcounts.fields ? allHeadcounts.fields : {}}
                routeKey={routeKey}
                permissions={permissions}
                isLoading={isLoading}
                apiCallBack={async () => {
                  await getAllHeadCounts('hc_transaction');
                  await getUserDataAccessByModule(getUserInfo().id, 3);
                  await getHcRequestType();
                }}
              />
            </>
          </TabPanel>
          <TabPanel value={tabValue} index={!pageView ? 1 : null}>
            <>
              <div className="lights-legend__container">
                {allHeadcounts?.allHcFilters?.map((filter) => (
                  <div
                    key={filter.value}
                    onClick={() => {
                      handleLegendClicked(filter);
                    }}
                    className={`lights-legend__item ${
                      selectedStatuses?.includes(filter.value)
                        ? 'lights-legend__item--active'
                        : ''
                    }`}
                  >
                    <div
                      className="lights-legend__color"
                      style={{
                        backgroundColor: filter?.color ? filter.color : 'transparent'
                      }}
                    ></div>
                    {filter?.value}
                  </div>
                ))}
              </div>
              <AllHcDataGrid
                dataAccess={dataAccess}
                transactionType={'transaction_leaver'}
                setrIdToFilter={setRequestIdToFilter}
                requestIdFilter={requestIdFilter}
                rows={
                  requestIdFilter !== null && allHcDs?.length
                    ? allHcDs?.filter((l) => l?.transactionHeadcountGroupId === requestIdFilter)
                    : allHcDs
                }
                columns={allHeadcounts?.leaversColumns?.columns ?? []}
                dropDownData={
                  allHeadcounts && allHeadcounts.dropdownValues ? allHeadcounts.dropdownValues : {}
                }
                leaversColumns={
                  allHeadcounts && allHeadcounts.leaversColumns ? allHeadcounts.leaversColumns : {}
                }
                fields={allHeadcounts && allHeadcounts.fields ? allHeadcounts.fields : {}}
                routeKey={routeKey}
                permissions={permissions}
                isLoading={isLoading}
                apiCallBack={async () => {
                  await getAllHeadCounts('transaction_leaver');
                  await getUserDataAccessByModule(getUserInfo().id, 3);
                  await getHcRequestType();
                }}
              />
            </>
          </TabPanel> */}
        </div>
      </div>
    </div>
  );
}
