import Login from 'views/sign-in';
import Home from 'views/home/index';
import ManageSegments from '../../views/manage-segments';
import ManageValues from 'views/manage-values';
import UserProfile from 'components/User/UserProfile';
import UserProfiles from 'views/user-profiles/User';
import FunctionalSecurity from 'views/functional-security';
import UiManagement from 'views/ui-management';
import StructureManagement from 'views/structure-management';
import CommonAttributesManagement from 'views/common-attributes-management';
import ManageJobDescription from '../../views/job-description';
import ManageJobs from 'views/manage-jobs';
import ApprovalManagement from 'views/approval-management';
import MyTasks from 'views/my-tasks';
import NotFound from 'components/Error/NotFound';
import ActivityLog from 'views/activity-log/Revision';
import stringSimilarity from 'string-similarity';
import * as allIcons from '@mui/icons-material';
import { isJSONValid } from './Helpers';
import PersonnelProfiles from 'views/personnel-profiles';
import ManageHeadCounts from '../../views/manage-headcounts/mhc/ManageHeadCounts';
import ManageTransfers from '../../views/manage-transfers/ManageTransfers';
import ManageTransfer from '../../views/manage-transfer';
import JDView from 'views/manage-headcounts/components/ViewJD';
import ManageJobType from '../../views/manage-job-type';
import ManageReqType from '../../views/manage-request-type';
import ManageHcType from '../../views/manage-headcount-type';
import ViewJD from 'views/job-description/ViewJD';
import ModuleManagement from 'views/module-management/ModuleManagement';
import Capabilities from 'views/capabilities/capabilities';
import ManageLights from 'views/manage-lights';
import ManageCvCategories from 'views/manage-cv-categories';
import ManageClientVital from 'views/manage-client-vital';
import ManageClientVitals from 'views/manage-client-vitals';
import NotificationManagement from 'views/notification-management';
import PerformanceMetric from 'views/performance-metrics';
import EmailDeliveryStatusHub from 'views/email-delivery-status-hub/EmailDeliveryStatusHub';
import ManageHeadCount from '../../views/manage-headcounts';
import DelegatedAccess from "views/delegated-access/DelegatedAccess";
import MasterCapability from "views/capabilities/components/master-capability/MasterCapability";
import EntityVerticalCapability from "views/capabilities/components/entity-vertical-capability/EntityVerticalCapability";
import TransactionCapability from "views/capabilities/components/transaction-capability/TransactionCapability";
import ManageEntityClients from 'views/manage-department-clients';
import ManageCVLightType from "../../views/manage-cv-light-types/ManageCVLightType";
import ManageDepartments from "../../views/manage-department-clients/manage-departments/ManageDepartments";
import FinancialForecast from "../../views/financial-forecast";
import FinancialForecastSetup from "../../views/financial-forecast/header-setup";
import FreelancerManagement from "../../views/freelancer-management/FreelancerManagement";
import Settings from "../../views/Settings";
import ExitManagement from '../../views/exit-management/ExitManagement';
import ManageExit from '../../views/exit-management/ManageExit';

export const getPageName = (data, key) => {
  if (key) {
    const pages = data.filter((d) => d.routeKey === key);
    return pages && pages.length > 0 && pages[0].page_properties
      ? isJSONValid(pages[0].page_properties)?.page?.name
      : null;
  }

  return null;
};

export const renderIcons = (icon) => {
  const iconsNames = Object.keys(allIcons);
  const matches = stringSimilarity.findBestMatch(icon, iconsNames);
  const bestMatch = matches.bestMatch.target;
  const ToRenderIcon = allIcons[bestMatch];
  return <ToRenderIcon />;
};

export const renderComp = (compName, key, propertiesData) => {
  switch (compName) {
    case 'SignInBasic':
      return <Login routeKey={key} pageName={getPageName(propertiesData, key)} />;
    case 'Home':
      return <Home routeKey={key} pageName={getPageName(propertiesData, key)} />;
    case 'ManageSegments':
      return <ManageSegments routeKey={key} pageName={getPageName(propertiesData, key)} />;
    case 'ManageValues':
      return <ManageValues routeKey={key} pageName={getPageName(propertiesData, key)} />;
    case 'UserProfile':
      return <UserProfile routeKey={key} />;
    case 'UserProfiles':
      return <UserProfiles routeKey={key} pageName={getPageName(propertiesData, key)} />;
    case 'FunctionalSecurity':
      return <FunctionalSecurity routeKey={key} pageName={getPageName(propertiesData, key)} />;
    case 'StructureManagement':
      return <StructureManagement routeKey={key} pageName={getPageName(propertiesData, key)} />;
    case 'CommonAttributesManagement':
      return <CommonAttributesManagement routeKey={key} pageName={getPageName(propertiesData, key)} />;
    case 'JobDescription':
      return <ManageJobDescription routeKey={key} pageName={getPageName(propertiesData, key)} />;
    case 'ManageJobs':
      return <ManageJobs routeKey={key} pageName={getPageName(propertiesData, key)} />;
    case 'ApprovalManagement':
      return <ApprovalManagement routeKey={key} pageName={getPageName(propertiesData, key)} />;
    case 'MyTasks':
      return <MyTasks routeKey={key} pageName={getPageName(propertiesData, key)} />;
    case 'ActivityLog':
      return <ActivityLog routeKey={key} pageName={getPageName(propertiesData, key)} />;
    case 'UiManagement':
      return <UiManagement routeKey={key} pageName={getPageName(propertiesData, key)} />;
    case 'ManageHeadCount':
      return <ManageHeadCount routeKey={key} pageName={getPageName(propertiesData, key)} />;
    case 'ManageHeadCounts':
      return <ManageHeadCounts routeKey={key} pageName={getPageName(propertiesData, key)} />;
    case 'ManageTransfers':
      return <ManageTransfers routeKey={key} pageName={getPageName(propertiesData, key)} />;
    case 'ManageTransfer':
      return <ManageTransfer routeKey={key} pageName={getPageName(propertiesData, key)} />;
    case 'ViewJd':
      return <JDView routeKey={key} pageName={getPageName(propertiesData, key)} />;
    case 'PersonnelProfiles':
      return <PersonnelProfiles routeKey={key} pageName={getPageName(propertiesData, key)} />;
    case 'ManageJobType':
      return <ManageJobType routeKey={key} pageName={getPageName(propertiesData, key)} />;
    case 'ManageReqType':
      return <ManageReqType routeKey={key} pageName={getPageName(propertiesData, key)} />;
    case 'ManageHcType':
      return <ManageHcType routeKey={key} pageName={getPageName(propertiesData, key)} />;
    case 'ViewJD':
      return <ManageJobDescription routeKey={key} pageName={getPageName(propertiesData, key)} />;
    case 'ModuleManagement':
      return <ModuleManagement routeKey={key} pageName={getPageName(propertiesData, key)} />;
    case 'capabilities':
      return <Capabilities />;
    case 'ManageLights':
      return <ManageLights routeKey={key} pageName={getPageName(propertiesData, key)} />;
    case 'ManageCvCategories':
      return <ManageCvCategories routeKey={key} pageName={getPageName(propertiesData, key)} />;
    case 'NotificationManagement':
      return <NotificationManagement routeKey={key} pageName={getPageName(propertiesData, key)} />;
    case 'ManageClientVital':
      return <ManageClientVital routeKey={key} pageName={getPageName(propertiesData, key)} />;
    case 'ManageClientVitals':
      return <ManageClientVitals routeKey={key} pageName={getPageName(propertiesData, key)} />;
    case 'PerformanceMetric':
      return <PerformanceMetric routeKey={key} pageName={getPageName(propertiesData, key)} />;
    case 'EmailDeliveryStatusHub':
      return <EmailDeliveryStatusHub routeKey={key} pageName={getPageName(propertiesData, key)} />;
    case "DelegatedAccess":
      return <DelegatedAccess routeKey={key} pageName={getPageName(propertiesData, key)} />
    case "MasterCapability":
      return <MasterCapability routeKey={key} pageName={getPageName(propertiesData, key)} />
    case "EntityVerticalCapability":
      return <EntityVerticalCapability routeKey={key} pageName={getPageName(propertiesData, key)} />
    case "TransactionCapability":
      return <TransactionCapability routeKey={key} pageName={getPageName(propertiesData, key)} />
    case "ManageEntityClients":
      return <ManageEntityClients routeKey={key} pageName={getPageName(propertiesData, key)} />
    case "ManageCVLightTypes":
      return <ManageCVLightType routeKey={key} pageName={getPageName(propertiesData, key)} />
    case "ManageDepartments":
      return <ManageDepartments routeKey={key} pageName={getPageName(propertiesData, key)}/>
    case "FinancialForecast":
      return <FinancialForecast routeKey={key} pageName={getPageName(propertiesData, key)}/>
    case "FinancialForecastSetup":
      return <FinancialForecastSetup routeKey={key} pageName={getPageName(propertiesData, key)}/>
    case "FreelancerManagement":
      return <FreelancerManagement routeKey={key} pageName={getPageName(propertiesData, key)}/>
    case "Settings":
      return <Settings routeKey={key} pageName={getPageName(propertiesData, key)}/>
    case "ExitManagement":
      return <ExitManagement routeKey={key} pageName={getPageName(propertiesData, key)}/>
    case "ManageExit":
      return <ManageExit routeKey={key} pageName={getPageName(propertiesData, key)}/>
    default:
      return <NotFound />;
  }
};
