import axios from "axios";
import { decryptObject, getOSAndBrowserInfo, getAuditMetricsObject, isJSONValid } from './Helpers';
import { toast } from "react-toastify";
import { handlePayloadEncryption } from "./PGPHelper";
import CryptoJS from 'crypto-js';
import moment from "moment-timezone";
import { MICROSOFT_AVATAR_URL } from '../constant';

let Auth_TOKEN = localStorage.getItem('_token') ? decryptObject(localStorage.getItem('_token')) : null
// Map to track ongoing requests
const ongoingRequests = new Map();
axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers.common['Accept'] = "application/json";
axios.defaults.headers.common['Content-Type'] = "application/json";
axios.defaults.headers.common['Authorization'] = `Bearer ${Auth_TOKEN}`;
axios.defaults.headers.common['api-key'] = process.env.REACT_APP_API_AUTH_KEY;
axios.defaults.headers.common['E-T'] = localStorage.getItem('expiry');
axios.defaults.headers.common['T-Z'] = moment.tz.guess()
axios.defaults.headers.common['X-Frame-Options'] = 'DENY';
axios.defaults.headers.common['Content-Security-Policy'] = "frame-ancestors 'none'";
axios.defaults.headers.common['Cache-Control'] = "no-store, no-cache, must-revalidate, max-age=0";
axios.defaults.headers.common['Pragma'] = 'no-cache';

const request = axios.create();

// URLs to exclude from performance measurement
const excludedUrls = ['user/create-or-update-device-token', 'metrics', 'metric/fe'];

request.interceptors.request.use(async (config) => {

    const source = axios.CancelToken.source();
    config.cancelToken = source.token;

    const requestKey = `${config.method}:${config.url}`;

    if (ongoingRequests.has(requestKey)) {
        // Cancel the previous request
        ongoingRequests.get(requestKey).cancel('Duplicate request canceled');
    }

    ongoingRequests.set(requestKey, source);

    let Auth_TOKEN = localStorage.getItem('_token') ? decryptObject(localStorage.getItem('_token')) : null
    let EXPIRY = localStorage.getItem('expiry')
    if (Auth_TOKEN) config.headers.Authorization = `Bearer ${Auth_TOKEN}`
    // Add responseType: 'blob' only to the requests that need it
    if (config.url === MICROSOFT_AVATAR_URL) {
        config.responseType = 'blob';
    }
    if (EXPIRY) config.headers['E-T'] = EXPIRY
    const excludedURLS = ['user/find-by-email', '/headcount/request', '/transaction-leaver', 'transaction-capability', 'transaction-approval/approve-reject-bulk', 'transaction-approval', '/headcount/request/freelancer-detail', 'emp-transfer', 'manage-transaction/bulk-hold', 'manage-transaction', 'exit-management']
    const encrypted = CryptoJS.AES.encrypt(localStorage.getItem('redirectPath') ? localStorage.getItem('redirectPath') : window.location.pathname, process.env.REACT_APP_API_URL).toString();
    const encoder = new TextEncoder();
    config.headers['F-U'] = encrypted
    localStorage.getItem('tokenParam') ? config.headers['T-P'] = localStorage.getItem('tokenParam') : null
    const auditMetrics = await getAuditMetricsObject();
    // Directly add auditMetrics to a custom header
    config.headers['x-audit-metrics'] = JSON.stringify(auditMetrics);

    if (config.method !== "get" && !excludedURLS.includes(config.url) && !config.url.startsWith('bulk-upload') && !config.url.startsWith('group/bulk-cancel/request/') && !config.url.startsWith('group/cancel/')) {
        /* if (config.data) config.data['F-U'] = window.location.pathname
         else
         {
             config.data = {}
             config.data['F-U'] = window.location.pathname
         }*/

        console.log('config.data', config.data)
        console.log('config.data.data.length', isJSONValid(config?.data?.data ?? {})?.length ?? 0)
        if (config.data) {
            config.data = await handlePayloadEncryption(config.data)
            config.headers['Content-Type'] = 'text/plain';
        }
    }

    if (!excludedUrls.includes(config.url)) config.metadata = { startTime: performance.now() };

    return config;
});

request.interceptors.response.use((response) => {
    if (response.config?.metadata?.startTime) {
        const endTime = performance.now();
        const startTime = response.config.metadata.startTime;
        const apiResponseTime = endTime - startTime / 1000;
        const data = {
            browserInfo: getOSAndBrowserInfo(),
            endPoint: response.config.url,
            responseTime: apiResponseTime,
            status: response.status,
            metaData: response.data
        }
        // request.post('metrics', data).then(r => {})
    }

    return response
}, (err) => {
    // whatever you want to do with the error
    if (err.response) {
        if (err.config && err.config.custom && err.config.custom.skipErrorHandler) {
            // Skip custom err handling
            return Promise.reject(err);
        }
        if (err.response.status === 422) {
            toast.error(err.response.data.error)
        }
        else if (err.response.status === 400) {
            toast.error(err.response.data.error)
        }
        else if (err.response.status === 404) {
            toast.error(err.response.data.error ?? "Resource not found.")
        }
        else if (err.response.status === 500) {
            toast.error(`Sorry, something went wrong.\nPlease contact teos@publicisgroupe.net for help.`)
        }
        else if (err.response.status === 403) {
            toast.error(err.response.data.error)
        }
        else if (err.response.status === 401) {
            localStorage.clear();
            sessionStorage.clear();
            toast.error(err.response.data.error)
            window.location.reload();
        }
    }
});

export default request;
