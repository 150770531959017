import React, { useCallback, useEffect, useState } from 'react';
import Loadable from 'react-loadable';
import { registerAllModules } from 'handsontable/registry';
import Skelton from '../../components/Skelton/defaultSkelton';
const HandsOnTableComponent = Loadable({
  loader: () => import('./components/handsOnTable'),
  loading: () => <Skelton />
});
const ActionButtons = Loadable({
  loader: () => import('./components/actionButtons'),
  loading: () => <Skelton />
});
import useFinancialForecast from '../../utils/hooks/useFinancialForecast';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import 'handsontable/dist/handsontable.min.css';
import './components/styles.scss';

registerAllModules();

const FinancialForecast = ({ routeKey, pageName }) => {
  const [isFullScreen, setFullScreen] = useState(false);
  const [hotTableRef, setHotTableRef] = useState(null);
  const hookFinancialForecast = useFinancialForecast();
  const { headers, renderTable, dataSource, dropdownData, setData, getDropdownData, sheetTwo, isSheetSubmitted, canSubmitSheet } = hookFinancialForecast;

  useEffect(() => {
    const callback = async () => {
      await getDropdownData();
    };

    callback();
  }, []);
  useEffect(() => {
    console.log('hotTableRef',hotTableRef)
  }, [hotTableRef])

  const handsOnTable = useCallback(() => {
    if (sheetTwo?.hasOwnProperty('data'))
    {
      let comparisonHeader = sheetTwo.headers[0].header.header;
      return <>
        <HandsOnTableComponent dataWithIDs={dropdownData?.data} setHotTableRef={setHotTableRef} {...hookFinancialForecast} />
        <br/> <h2>Comparison sheet</h2>
        <HandsOnTableComponent dataWithIDs={dropdownData?.data} dataSource={sheetTwo.data} headers={comparisonHeader} setData={setData} alignHeaders={hookFinancialForecast.alignHeaders} addClassesToRows={hookFinancialForecast.addClassesToRows} toHide={true} />
      </>

    }
    return <HandsOnTableComponent setHotTableRef={setHotTableRef} dataWithIDs={dropdownData?.data} {...hookFinancialForecast} />;
  }, [headers, dataSource, dropdownData, isSheetSubmitted, hotTableRef, setHotTableRef, canSubmitSheet]);

  return (
    <div className="__body__section __financial__forcast__">
      <div className="__financial__forcast__section__">
        <div className="__financial__forecast__action__buttons__">
          <ActionButtons hotTableRef={hotTableRef} {...{ ...hookFinancialForecast, setFullScreen }} />
        </div>
        <div className="__financial__forecast__table__">
          {renderTable && dataSource && handsOnTable()}
        </div>
      </div>
      <Modal
        open={isFullScreen}
        onClose={() => setFullScreen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            overflowY: 'auto',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '100%',
            height: '100%',
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4
          }}
        >
          <div className="__financial__forcast__section__">
            <div className="__financial__forecast__action__buttons__">
              <ActionButtons {...{ ...hookFinancialForecast }} />
            </div>
            <div className="__financial__forecast__table__ __mt__15">{renderTable && handsOnTable()}</div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default FinancialForecast;
