import React, { useState, useEffect } from 'react';
import { useIsLoading } from './useIsLoading';
import request from '../services/Http';
import 'react-toastify/dist/ReactToastify.css';
import Handsontable from 'handsontable';
import {
  ODD_ROW_CLASS,
  handleActionsEnum,
  __headers,
  __children,
  __levels,
  __monthsKey,
  __futureMonthsKey,
  __futureHeader,
  __ffData
} from '../../views/financial-forecast/components/constants';
import { isJSONValid } from 'utils/services/Helpers';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

export default function useFinancialForecast() {
  const headerAlignments = new Map([
    ['9', 'htCenter'],
    ['10', 'htRight'],
    ['12', 'htCenter']
  ]);
  const API_END_POINT = 'fc-header';
  const API_FF_Structure = 'transaction-financial-forecast';
  const { setIsLoading, isLoading } = useIsLoading();
  const [renderTable, setRenderTable] = React.useState(false);
  const [headers, setHeaders] = useState(null);
  const [dataSource, setDataSource] = useState(null);
  const [FFStructureToDisplayWithData, setFFStructureToDisplayWithData] = useState(null);
  const [FFStructureToDisplayForHeader, setFFStructureToDisplayForHeader] = useState(null);
  const [dropdownData, setDropdownData] = useState(null)
  const [hfm, setHfm] = useState(null);
  const [businessCategory, setBusinessCategory] = useState(null);
  const [client, setClient] = useState(null);
  const [currency, setCurrency] = useState(null);
  const [year1, setYear1] = useState(null);
  const [year2, setYear2] = useState(null);
  const [version1, setVersion1] = useState(null);
  const [version2, setVersion2] = useState(null);
  const [selectedView, setSelectedView] = useState('single');
  const [data, setData] = useState([])
  const [sheetTwo, setSheetTwo] = useState(null)
  const [isSheetSubmitted, setIsSheetSubmitted] = useState(false)
  const [hasSheetEdited, setHasSheetEdited] = useState(false)
  const [canSubmitSheet, setCanSubmitSheet] = useState(false)

  useEffect(() => setIsLoading(false), []);
  useEffect(() => {
    const fetchData = async () => {
      await getDataForTable();
    };

    fetchData();
  }, [selectedView, year1, year2, version1, version2]);
  useEffect(() => {
    resetSettings();
  }, [businessCategory, hfm, client, year1]);
  useEffect(() => {
    if (year2 && year1) {
      // setHeaders(__ffData.headers.forecast);
      // setDataSource(__ffData.dataSource.forecast);
      // setRenderTable(true);
      const header = isJSONValid(FFStructureToDisplayForHeader?.[0]?.header);
      setHeaders(header?.header);
      setDataSource(FFStructureToDisplayWithData);
      setRenderTable(true);
    }
    else if (year1) {
      // setHeaders(__ffData.headers.history);
      const header = isJSONValid(FFStructureToDisplayForHeader[0].header);
      setHeaders(header?.header);
      // setDataSource(__ffData.dataSource.history);
      setDataSource(FFStructureToDisplayWithData);
      setRenderTable(true);
    }
  }, [year2]);
  useEffect(() => {
    console.log('FFStructureToDisplayForHeader', FFStructureToDisplayForHeader)
    // Check if the data is available
    if (FFStructureToDisplayForHeader && FFStructureToDisplayForHeader.length > 0) {
      const header = isJSONValid(FFStructureToDisplayForHeader[0]?.header);
      setHeaders(header?.header);
      setDataSource(FFStructureToDisplayWithData);

      // Set renderTable to true only after data is set
      setRenderTable(true);
    }
  }, [FFStructureToDisplayForHeader]);

  const resetSettings = () => {
    if (hfm === null || client === null || year1 === null) {
      setRenderTable(false);
      handleAction(handleActionsEnum.YEAR_1);
      handleAction(handleActionsEnum.YEAR_2);
    }
  };

  const addClassesToRows = (TD, row, column, prop, value, cellProperties) => {
    // Adding classes to `TR` just while rendering first visible `TD` element
    if (column !== 0) {
      return;
    }

    const parentElement = TD.parentElement;

    if (parentElement === null) {
      return;
    }

    // Add class to odd TRs
    if (row % 2 === 0) {
      Handsontable.dom.addClass(parentElement, ODD_ROW_CLASS);
    } else {
      Handsontable.dom.removeClass(parentElement, ODD_ROW_CLASS);
    }
  };

  const drawCheckboxInRowHeaders = function drawCheckboxInRowHeaders(row, TH) {
    const input = document.createElement('input');

    input.type = 'checkbox';

    if (row >= 0 && this.getDataAtRowProp(row, '0')) {
      input.checked = true;
    }

    Handsontable.dom.empty(TH);

    TH.appendChild(input);
  };

  const changeCheckboxCell = function changeCheckboxCell(event, coords) {
    const target = event.target;

    if (coords.col === -1 && event.target && target.nodeName === 'INPUT') {
      event.preventDefault(); // Handsontable will render checked/unchecked checkbox by it own.

      this.setDataAtRowProp(coords.row, '0', !target.checked);
    }
  };

  const alignHeaders = (e, column, TH) => {
    if (column < 0) {
      return;
    }

    if (TH.firstChild) {
      const alignmentClass = e.isRtl() ? 'htRight' : 'htLeft';

      if (headerAlignments.has(column.toString())) {
        Handsontable.dom.removeClass(TH.firstChild, alignmentClass);
        Handsontable.dom.addClass(TH.firstChild, headerAlignments.get(column.toString()));
      } else {
        Handsontable.dom.addClass(TH.firstChild, alignmentClass);
      }
    }
  };

  const getSetupHeader = async () => {
    setIsLoading(true);
    try {
      const res = await request.get(API_END_POINT);
      if (res) {
        console.log('fetch setup header:: ', res);
        // toast.success(res.data.data);
        return res.data;
      }
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  };

  const getDropdownData = async () => {
    setIsLoading(true);
    try {
      const res = await request.get(`${API_FF_Structure}/dd-data`);
      if (res) {
        console.log('fetch data for dd:: ', res);
        // toast.success(res.data.data);
        setDropdownData(res.data.dropdownData)
        return res.data.dropdownData;
      }
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  };

  const buildQueryParams = () => {
    const params = [
      `masterBusinessCategoryId=${businessCategory}`,
      `masterOrgHfmId=${hfm}`,
      `masterClientId=${client}`,
      year1 && `year1=${year1}`,
      year2 && `year2=${year2}`,
      version1 && `version1=${version1}`,
      version2 && `version2=${version2}`,
    ].filter(Boolean).join('&');

    return `?${params}`;
  };

  const getDataForTable = async () => {
    if (!businessCategory || !hfm || !client || !year1) return; // Ensure the required parameters are available

    setIsLoading(true);
    try {
      const res = await request.get(`${API_FF_Structure}${buildQueryParams()}`);
      if (res) {
        console.log('fetch data for table:: ', res);
        setDropdownData({ ...dropdownData, ...res.data.dropdownData });
        setFFStructureToDisplayWithData(res?.data?.data);
        setFFStructureToDisplayForHeader(res?.data?.headers);
        setIsSheetSubmitted(res.data.isSubmitted)
        setData(res.data.savedTransactions)
        setCanSubmitSheet(res.data.canSubmit)
        if (res.data?.sheet2) setSheetTwo(res.data?.sheet2)
        return res.data;
      }
    } catch (e) {
      console.error('Error fetching table data:', e);
    } finally {
      setIsLoading(false);
    }
  };

  const submitHeader = async (body) => {
    setIsLoading(true);
    try {
      const res = await request.post(API_END_POINT, body);
      if (res) {
        toast.success(res.data.data);
      }
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  };

  const removeHeaderVersion = async (idx) => {
    setIsLoading(true);
    try {
      const res = await request.delete(API_END_POINT, { data: { headerIds: idx } });
      if (res) {
        toast.success(res.data);
      }
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  };

  const saveAsExcel = (hotInstance) => {
    try {
      const exportPlugin = hotInstance?.getPlugin('exportFile');
      exportPlugin?.downloadFile('csv', {
        bom: false,
        columnDelimiter: ',',
        columnHeaders: false,
        exportHiddenColumns: false,
        exportHiddenRows: false,
        fileExtension: 'csv',
        filename: `RollingForecast_${new Date().toLocaleDateString()}`,
        mimeType: 'text/csv',
        rowDelimiter: '\r\n',
        rowHeaders: true,
      });
      console.info('data saved as excel file!');
    }
    catch (e) {
      console.error('error in exporting excel file', e)
    }
  };

  const handleAction = (type = null, value = null) => {
    switch (type) {
      case handleActionsEnum.BUSINESS_CATEGORY:
        setRenderTable(false);
        setBusinessCategory(value);
        setHfm(null);
        setClient(null);
        setYear1(null);
        break;
      case handleActionsEnum.HFM:
        setRenderTable(false);
        setHfm(value);
        setClient(null);
        setYear1(null);
        break;
      case handleActionsEnum.CLIENT:
        setRenderTable(false);
        setClient(value);
        setYear1(null);
        break;
      case handleActionsEnum.CURRENCY:
        setCurrency(value);
        break;
      case handleActionsEnum.YEAR_1:
        setYear1(value);
        break;
      case handleActionsEnum.YEAR_2:
        setYear2(value);
        break;
      case handleActionsEnum.VERSION_1:
        setVersion1(value);
        break;
      case handleActionsEnum.VERSION_2:
        setVersion2(value);
        break;
      default:
        setBusinessCategory(value);
        setHfm(value);
        setClient(value);
        setCurrency(value);
        setYear1(value);
        setYear2(value);
        setVersion1(value);
        setVersion2(value);
        break;
    }
  };

  const submitSheet = async () => {
    try {
      Swal.fire({
        title: "Are you sure?",
        text: "You'll not be able to edit the sheet.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Submit it!",
        heightAuto: false,
        height: "200px",
        customClass: {
          container: '__swal__continer__ __font__family',
          confirmButton: '__default__button__layout',
          cancelButton: '__default__button__layout'
        }
      })
        .then(async (res) => {
          if (res.isConfirmed)
          {
            data?.map(d => {
              d.isSubmitted = true
              d.isDraft = false
              return d
            })
            await createUpdateTransactions(data);
          }
      })
    }
    catch (e) {}
  }

  const save = async () => {
    try {
      data?.map(d => d.isDraft = true)
      await createUpdateTransactions(data)
    }
    catch (e) {}
  };

  const createUpdateTransactions = async (data) => {
    setIsLoading(true);
    try {
      data?.map(d => {
        delete d['grandparent']
        delete d['parent']
        delete d['child']
        d['masterBusinessCategoryId'] = businessCategory
        d['masterOrgHfmId'] = hfm
        d['masterClientId'] = client
        d['masterCurrencyId'] = currency
        d['year'] = year1
      })
      const res = await request.post(API_FF_Structure, data)
      if (res) {
        setData([])
        setHasSheetEdited(false)
        toast.success(res.data.data)
        await getDataForTable()
      }
    }
    catch (e) { }
    finally {
      setIsLoading(false)
    }
  }

  return {
    hfm, client, currency, year1, year2,
    version1, version2, headers, renderTable,
    dataSource, isLoading, setIsLoading,
    changeCheckboxCell, alignHeaders,
    drawCheckboxInRowHeaders, addClassesToRows,
    saveAsExcel, handleAction, submitHeader,
    getSetupHeader, removeHeaderVersion,
    getDropdownData, getDataForTable,
    save, submitSheet, dropdownData, setData, data,
    selectedView, setSelectedView, sheetTwo, isSheetSubmitted,
    hasSheetEdited, setHasSheetEdited, businessCategory, canSubmitSheet
  };
}
