import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import Loadable from 'react-loadable';
import usePagePermissions from 'utils/hooks/usePagePermissions';
import './components/manage-transfer.scss';
import BaseLayOut from 'components/Layout/BaseLayOut';
import { Context } from 'utils/context/store/Store';
import useManageTransfer from 'utils/hooks/useManageTransfer';
import { SET_TRANSFERS } from 'utils/context/store/Constants';
import Skelton from '../../components/Skelton/defaultSkelton';
const TransferDataGrid = Loadable({
  loader: () => import('./components/TransferDataGrid'),
  loading: () => <Skelton />
});

export default function ManageTransfer({ routeKey, pageName }) {
  const { id } = useParams();
  const { getPermissionsForPage } = usePagePermissions(routeKey);
  const { getTransferRequest, isLoading } = useManageTransfer('manage-supervisor');
  const [dgData, setDgData] = useState([]);
  const [{ transfers }, dispatch] = useContext(Context);
  const pageTitle = pageName === 'manage-hc' ? 'manage-supervisor' : 'Manage Supervisor';

  useEffect(async () => {
    await getTransferRequest(false, id ?? null);

    return () => {
      dispatch({
        type: SET_TRANSFERS,
        payload: {}
      });
    };
  }, []);

  return (
    // <BaseLayOut pageTitle={pageTitle}>
    <div className="__body__section">
      <div className="manage-headcount">
        <div className="__font__family__regular __data__grid__container">
          {transfers ? (
            <TransferDataGrid
              columns={transfers?.columns || []}
              rows={transfers?.rows ?? []}
              dropDownData={transfers?.dropdownValues || {}}
              isLoading={isLoading}
              routeKey={routeKey}
              permissions={getPermissionsForPage(routeKey)}
              allowAdding={transfers?.isDraft ? true : !id}
              showButton={!id}
              allowSelection={!!id}
              apiCallBack={async () => await getTransferRequest(id)}
              isDraft={transfers?.isDraft}
              groupId={id}
              setDgData={setDgData}
            />
          ) : null}
        </div>
      </div>
    </div>
    // </BaseLayOut>
  );
}
