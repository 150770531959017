import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import { CustomDateBox } from "components/CustomDataGridComponents";
import MDAlert from "components/MDAlert";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataGrid, {
  AsyncRule,
  Button,
  Column,
  Editing,
  Export,
  HeaderFilter,
  Pager,
  Paging,
  RequiredRule,
  RowDragging,
  Scrolling,
  SearchPanel,
  Selection,
} from "devextreme-react/data-grid";
import * as React from "react";
import { useEffect, useMemo, useRef, useState } from "react";
import Swal from "sweetalert2";
import { cloneIconClick, onRowExpanding } from "utils/services/DatagridHelpers";
import { DEButton, createSanitizeAsyncRule } from "../../../utils/services/Helpers";

export const CategoriesItemsGrid = ({
  columns,
  defaultKey = "id",
  isLoading,
  permissions,
  allowAdding = true,
  allowUpdating = true,
  allowSelection = true,
  allowDeleting = true,
  handleDelete,
  dataSource,
  setDataSource,
  categoryData,
  setHasDataChanged,
}) => {
  const [dataColumns, setDataColumns] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const sanitizeAsyncRule = createSanitizeAsyncRule("Invalid characters detected. Please remove any special characters.");
  const dataGridRef = useRef();

  useEffect(() => {
    setDataColumns(columns);
    // cleanup on unmount
    return () => {
      setDataColumns([]);
    };
  }, []);

  useEffect(() => {
    setDataColumns(columns);
  }, [columns]);

  /**
   * @param selectedRowKeys
   * @param selectedRowsData
   * used to get selected rows detail of data-grid
   **/
  function onSelectionChanged({ selectedRowsData }) {
    setSelectedRowKeys(selectedRowsData);
  }

  /**
   * get selected rows
   **/
  const hasSelected = selectedRowKeys.length > 0;

  /**
   * @param col
   * function use to handle rendering of fields
   **/
  function renderField(col) {
    if (col.type === "checkbox" || col.type === "toggle") {
      return (
        <Column
          dataType="boolean"
          showEditorAlways
          allowEditing={col.editable}
          visible={col.is_visible}
          allowSearch={col.is_searchable}
          allowSorting={col.is_sortable}
          dataField={col.dataIndex}
          caption={col.title}
          setCellValue={function (rowData, value) {
            this.defaultSetCellValue(rowData, value);
          }}
        >
          {col.required ? <RequiredRule /> : null}
        </Column>
      );
    } else if (col.type === "actions") {
      return (
        <Column
          allowEditing={col.editable}
          visible={col.is_visible}
          allowSearch={col.is_searchable}
          allowSorting={col.is_sortable}
          type="buttons"
          dataField={col.dataIndex}
          caption={col.title}
          fixed={false}
          width={"100px"}
        >
          <Button
            name="delete"
            icon={"trash"}
            visible={(e) => e && e.row && e.row.data && e.row.data.newRow === true}
          />
          <Button
            hint="Clone"
            icon="copy"
            visible={(e) => permissions && permissions.canCreate}
            onClick={(e) => cloneIconClick(e, dataSource, setDataSource)}
          />
        </Column>
      );
    } else if (col.type === "date") {
      return (
        <Column
          dataType={"date"}
          editCellComponent={CustomDateBox}
          allowEditing={col.editable}
          visible={col.is_visible}
          allowSearch={col.is_searchable}
          allowSorting={col.is_sortable}
          dataField={col.dataIndex}
          caption={col.title}
          format={"dd-MM-yyyy"}
        >
          {col.required ? <RequiredRule /> : null}
        </Column>
      );
    } else if (col.type === "string"){
      return (
        <Column
          allowEditing={col.editable}
          visible={col.is_visible}
          allowSearch={col.is_searchable}
          allowSorting={col.is_sortable}
          dataField={col.dataIndex}
          caption={col.title}
        >
          {col.required ? <RequiredRule /> : null}
          <AsyncRule {...sanitizeAsyncRule} />
        </Column>
      );
    } else {
      return (
        <Column
          allowEditing={col.editable}
          visible={col.is_visible}
          allowSearch={col.is_searchable}
          allowSorting={col.is_sortable}
          dataField={col.dataIndex}
          caption={col.title}
        >
          {col.required ? <RequiredRule /> : null}
        </Column>
      );
    }
  }

  /**
   * @param e
   * initialize new row in the data-grid
   **/
  const onInitNewRow = (e) => {
    e.data.newRow = true;
    e.data.sequence = dataSource.length + 1;
    e.data.status = true;
    e.data.isCommentMandatory = true;
  };

  /**
   * function used to reorder rows based on sequence
   **/
  const onReorder = (e) => {
    const visibleRows = e.component.getVisibleRows();
    const newTasks = [...dataSource];

    const toIndex = newTasks.findIndex(
      (item) => item.sequence === visibleRows[e.toIndex].data.sequence
    );
    const fromIndex = newTasks.findIndex((item) => item.sequence === e.itemData.sequence);

    newTasks.splice(fromIndex, 1);
    newTasks.splice(toIndex, 0, e.itemData);

    // Update the sequence property of each item based on its new index
    newTasks.forEach((item, index) => {
      item.sequence = index + 1;
    });

    categoryData.master_cv_items = [];
    categoryData.master_cv_items = newTasks;

    setDataSource(newTasks);
    setHasDataChanged(true);
  };

  // save new row in the data-grid
  const onSave = (e) => {
    if (e && e.changes.length) {
      if (e.changes[0].type === "remove") {
        const dataSourceArray = [...dataSource];
        const deleteItemId = e.changes[0].key;
        if (deleteItemId) {
          const deleteFromTable = dataSourceArray.length
            ? dataSourceArray.filter((data) => data.id !== deleteItemId)
            : [];
          setDataSource(deleteFromTable);
          setHasDataChanged(deleteFromTable.some(fd => fd.hasOwnProperty('newRow')))
        }
      } else {
        const insertMode = e && e.changes[0].type === "insert";
        const updateMode = e && e.changes[0].type === "update";
        if (updateMode) e.changes[0]["data"]["rowEdited"] = true;
        const updatedData = e.changes[0].data;
        let finalData = [];
        if (insertMode) {
          finalData.push(updatedData);
        }
        let finalResult = [];
        if (dataSource && dataSource.length) {
          finalResult = _.unionBy(insertMode ? finalData : updatedData, dataSource);
        } else {
          finalResult.push(updatedData);
        }
        setDataSource(finalResult);
        if (
          categoryData.hasOwnProperty("master_cv_items") &&
          categoryData["master_cv_items"].length
        ) {
          const fData = [...categoryData["master_cv_items"], ...finalResult];
          categoryData["master_cv_items"] = categoryData["master_cv_items"].every((e) =>
            e.hasOwnProperty("key")
          )
            ? [...new Map(fData.map((item) => [item["key"], item])).values()]
            : [...new Map(fData.map((item) => [item["id"], item])).values()];
        } else {
          categoryData["master_cv_items"] = [];
          categoryData["master_cv_items"] = finalResult;
          categoryData.count = finalResult.length;
        }
        setHasDataChanged(true);
      }
    }
  };

  /**
   * function used to handle delete part of data-grid
   **/
  const manageDelete = async () => {
    // const result = [...selectedRowKeys];
    const msg = "You won't be able to revert this!";

    Swal.fire({
      title: "Are you sure?",
      text: msg,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      heightAuto: false,
      height: "200px",
      customClass: {
        container: '__swal__continer__ __font__family',
        confirmButton: '__default__button__layout',
        cancelButton: '__default__button__layout'
      }
    }).then(async (res) => {
      const newRecords = dataSource.filter((ds) => ds.hasOwnProperty("newRow"));
      let newData = [...dataSource];
      if (res.isConfirmed) {
        const deleteFromApi = selectedRowKeys.filter(
          (a) => a.hasOwnProperty("id") && !a.hasOwnProperty("newRow")
        );
        const deleteFromTable = selectedRowKeys.filter((a) => a.hasOwnProperty("newRow"));
        const result = deleteFromApi.map((a) => a.id);

        if (deleteFromApi.length > 0 && deleteFromTable.length > 0) {
          if (selectedRowKeys.length === dataSource.length) {
            await handleDelete(result, dataSource[0]["masterCvCategoryId"], 'item');
          } else {
            await handleDelete(result, null, 'item');
          }
        } else if (deleteFromApi && deleteFromApi.length > 0) {
          if (selectedRowKeys.length === dataSource.length) {
            await handleDelete(result, dataSource[0]["masterCvCategoryId"], 'item');
          } else {
            await handleDelete(result, null, 'item');
          }
        } else if (deleteFromTable && deleteFromTable.length > 0) {
          deleteFromTable.map((a) => {
            newData = newData.filter((item) => item.id !== a.id);
          });
          setDataSource(newData);
        }
        setSelectedRowKeys([]);
      }
      setSelectedRowKeys([]);
    });
  };

  /**
   * custom function using useMemo to avoid re-renders unless the states listed are changed
   **/
  const Comp = useMemo(() => {
    try {
      return (
        <div id="items-data-grid">
          {hasSelected > 0 ? (
            <React.Fragment>
              <br />
              <MDAlert color="light">
                <MDTypography variant="subtitle2">
                  {`Selected ${selectedRowKeys.length} ${selectedRowKeys.length === 1 ? "item" : "items"
                    }`}
                </MDTypography>

                <Divider orientation="vertical" color="dark" flexItem />

                <MDBox>
                  <Grid container spacing={2}>
                    {permissions && permissions.canDelete ? (
                      <Grid item>
                        <Button icon="trash" />
                        <DEButton
                          stylingMode={"contained"}
                          type={"danger"}
                          icon="trash"
                          onClick={() => manageDelete()}
                        />
                      </Grid>
                    ) : null}
                  </Grid>
                </MDBox>
              </MDAlert>
            </React.Fragment>
          ) : null}
          <DataGrid
            id="itemsGrid"
            showBorders
            onRowExpanding={onRowExpanding}
            onSaved={onSave}
            showColumnLines
            showRowLines
            rowAlternationEnabled
            ref={dataGridRef}
            onInitNewRow={onInitNewRow}
            onSelectionChanged={onSelectionChanged}
            allowColumnResizing
            disabled={isLoading}
            dataSource={dataSource}
            key={defaultKey ?? "id"}
            keyExpr={defaultKey ?? "id"}
            repaintChangesOnly={true}
          >
            <Scrolling showScrollbar="always" mode="standard" />

            {allowSelection ? (
              <Selection
                allowSelectAll
                mode="multiple"
                selectAllMode={"page"}
                showCheckBoxesMode={"always"}
              />
            ) : null}
            <RowDragging allowReordering onReorder={onReorder} />
            <HeaderFilter visible allowSearch />
            <SearchPanel visible />
            <Paging defaultPageSize={25} />
            <Pager visible showNavigationButtons showInfo displayMode={"full"} />
            <Export enabled allowExportSelectedData />
            <Editing
              mode="cell"
              refreshMode={"repaint"}
              allowUpdating={permissions && permissions.canCreate && allowUpdating}
              allowAdding={permissions && permissions.canCreate && allowAdding}
              allowDeleting={allowDeleting}
            />
            {dataColumns && dataColumns.length ? dataColumns.map((d, i) => renderField(d)) : null}
          </DataGrid>
        </div>
      );
    } catch (e) {
      console.log("error from CV", e);
    }
  }, [dataSource, dataColumns, selectedRowKeys, isLoading]);

  return <React.Fragment>{Comp}</React.Fragment>;
};
