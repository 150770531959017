import React, { useEffect, useContext } from 'react';
import Loadable from 'react-loadable';
import { Context } from '../../utils/context/store/Store';
import BaseLayOut from '../../components/Layout/BaseLayOut';
import useJobType from '../../utils/hooks/useJobType';
import usePagePermissions from '../../utils/hooks/usePagePermissions';
import useBulkUpload from 'utils/hooks/useBulkUpload';
import Skelton from '../../components/Skelton/defaultSkelton';
const HcTypeDataGrid = Loadable({
  loader: () => import('../manage-headcount-type/DataGrid/HcTypeDataGrid'),
  loading: () => <Skelton />
});

export default function ManageJobType({ routeKey, pageName }) {
  const { fetchJobType, updateOrCreateJobType, deleteJobType, isLoading } = useJobType(routeKey);
  const { bulkUpload } = useBulkUpload(routeKey);
  const [{ jobType }, dispatch] = useContext(Context);
  const { getPermissionsForPage } = usePagePermissions(routeKey);
  const permissions = getPermissionsForPage();

  useEffect(async () => {
    await fetchJobType();
  }, []);

  return (
    // <BaseLayOut pageTitle={pageName ?? "Manage Job Type"}>
    <div className="__body__section __manage__segments__">
      <HcTypeDataGrid
        columns={jobType && jobType.columns ? jobType.columns : []}
        rows={jobType && jobType.rows ? jobType.rows : []}
        isLoading={isLoading}
        permissions={permissions}
        hitApi={updateOrCreateJobType}
        handleDelete={deleteJobType}
        bulkUploadApi={bulkUpload}
        apiCallback={fetchJobType}
        tableName={jobType?.tableName}
        uploadTemplateLink={jobType?.uploadTemplateLink}
      />
    </div>
    // </BaseLayOut>
  );
}
