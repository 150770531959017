import React, { useContext, useEffect, useMemo, useState } from "react";
import MDTypography from "components/MDTypography";
import { Context } from "../../../utils/context/store/Store";
import useWorkflowStep from 'utils/hooks/useWorkflowStep';
import MDBox from "components/MDBox";
import { Grid } from "@mui/material";
import Select from "react-select";
import { selectCustomStyles, Accordion, AccordionSummary, AccordionDetails, DEButton, sanitizeInput } from "../../../utils/services/Helpers";
import { toast } from 'react-toastify';
import FormField from "components/FormField";
import { isObjectEmpty } from 'utils/services/Helpers';
import TreeList, { Column, RowDragging, Button, Editing, Selection, Scrolling } from "devextreme-react/tree-list";
import _ from "lodash";
import MDAlert from "components/MDAlert";
import Divider from "@mui/material/Divider";
import Swal from "sweetalert2";
import { SET_NEW_WF_STEP } from "utils/context/store/Constants";
import { Switch } from "antd";

const WorkflowStep = ({ workflow, data, updateButtonStateOfParent = null, permissions, valueToFetch, fetchFromApi }) => {

  const { getStepsByWorkflow, getRecordByField, deleteWorkflowSteps, records, isLoading } = useWorkflowStep(workflow)
  const [{ newWfStep }, dispatch] = useContext(Context)

  const [conditionType, setConditionType] = useState(null);
  const [subProcessCondType, setSubProcessCondType] = useState(null);
  const [workflowAccessId, setWorkflowAccessId] = useState(null);
  const [tableName, setTableName] = useState(null);
  const [tableColumnName, setTableColumnName] = useState(null);
  const [columnOperation, setColumnOperation] = useState(null);
  const [conditionValue, setConditionValue] = useState(null);
  const [labelConditionValue, setLabelConditionValue] = useState(null);
  const [tableId, setTableId] = useState(null);
  const [subProcess, setSubProcess] = useState(0);
  const [dataId, setDataId] = useState(null);
  const [parId, setParId] = useState(null);
  const [dataSource, setDataSource] = useState([]);
  const [sPDataSource, setSpDataSource] = useState([]);
  const [dropDownData, setDropDownData] = useState({});
  const [editWfStep, setEditWfStep] = useState({})
  const [subProcessStep, setSubProcessStep] = useState({})
  const [isDataChanged, setIsDataChanged] = useState(false)
  const [notifyApprover, setNotifyApprover] = useState(true)
  const [notifyOnAction, setNotifyOnAction] = useState(false)
  const [notifyWorkflowAccessId, setNotifyWorkflowAccessId] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const expandedRowKeys = [1];

  useEffect(async () => {
    // await getStepsByWorkflow(workflow)
    if (data.data.workflow_step.length > 0) {
      setDataSource(data.data.workflow_step)
    }
  }, [])

  useEffect(async () => {
    if (columnOperation && !isObjectEmpty(columnOperation)) {
      if (tableColumnName && tableColumnName.type === "STRING" && (columnOperation.value === "=" || columnOperation.value === "in")) {
        await getRecordByField(tableName.modelName, tableColumnName.name)
      }
    }
  }, [columnOperation])

  useEffect(() => {
    if (labelConditionValue && records) {
      const labelArray = labelConditionValue && labelConditionValue.toString().split(",")
      let condVal = []
      labelArray.map(obj => {
        const filter = records && records.filter(o => o.label === obj)
        if (condVal.length) {
          condVal = [...condVal, ...filter]
        } else {
          condVal.push(...filter)
        }
        setConditionValue(condVal)
      })
    }
  }, [records])


  useEffect(() => { }, [conditionValue])
  useEffect(() => { }, [notifyWorkflowAccessId])
  useEffect(() => { }, [labelConditionValue])
  useEffect(() => { }, [subProcess])
  useEffect(() => { }, [dataId])
  useEffect(() => { }, [parId])
  useEffect(() => { }, [dataSource])
  useEffect(() => { }, [sPDataSource])
  useEffect(() => { }, [dropDownData])
  useEffect(() => { }, [editWfStep])
  useEffect(() => { }, [subProcessStep])
  useEffect(() => { }, [isDataChanged])
  useEffect(() => { }, [data])
  useEffect(() => { }, [selectedRowKeys])
  useEffect(() => { }, [conditionType])
  useEffect(() => { }, [subProcessCondType])
  useEffect(() => { }, [workflowAccessId])
  useEffect(() => { }, [tableName])
  useEffect(() => { }, [tableColumnName])

  /**
  * @param e
  * function to upsert records to the main tree list
  **/
  const performAction = (e) => {
    const dataToSave = {
      id: dataId ? dataId : dataSource.length + 1,
      sequence: dataId ? dataId : dataSource.length + 1,
      uniqueKey: dataId ? dataId : dataSource.length + 1,
      tableId: tableId ?? null,
      parentId: -1,
      workflowId: data.data.id,
      workflowAccessId: workflowAccessId ? workflowAccessId.label : null,
      conditionType: conditionType,
      tableName: tableName ? tableName.value : null,
      tableColumnName: tableColumnName ? tableColumnName.value : null,
      foreignKeyValue: tableName ? tableName.foreignKeyValue : null,
      columnOperation: columnOperation ? columnOperation.value : null,
      conditionValue: conditionValue
        ? typeof conditionValue === "object"
          ? Array.isArray(conditionValue)
            ? conditionValue.map(obj => { return obj['label'] })
            : conditionValue.label
          : conditionValue
        : null,
      notifyApprover,
      notifyOnAction,
      notifyWorkflowAccessId: notifyWorkflowAccessId ? notifyWorkflowAccessId.map(obj => { return obj['label'] }) : null,
      subProcess,
      ddworkflowAccessId: workflowAccessId,
      ddnotifyWorkflowAccessId: notifyWorkflowAccessId,
      ddtableName: tableName,
      ddtableColumnName: tableColumnName,
      ddcolumnOperation: columnOperation,
      ddconditionValue: conditionValue,
    }
    if (data.data.workflow_step.length === 0 && subProcess) {
      toast.error("Please add a step before creating a sub process")
    } else {
      setParId(null)
      const newData = [...data.data.workflow_step]
      const i = newData.findIndex(_item => _item.id === dataToSave.id);
      if (i > -1) newData[i] = dataToSave;
      else newData.push(dataToSave);
      // setIsDataChanged(true)   
      data.data.workflow_step = newData
      data.data.dataChanged = true
      updateButtonStateOfParent(true)
      setDataSource(data.data.workflow_step)
      toast.success("Step added in table successfully!")
      handleReset()
    }
  }

  /**
* @param e
* function to upsert records to the sub-process tree list
**/
  const saveToSubProcess = (e) => {
    const dataToSave = {
      id: dataId ? dataId : sPDataSource.length + 1,
      sequence: dataId ? dataId : sPDataSource.length + 1,
      uniqueKey: dataId ? dataId : dataSource.length + 1,
      tableId: tableId ?? null,
      parentId: -1,
      workflowId: data.data.id,
      workflowAccessId: workflowAccessId ? workflowAccessId.label : null,
      notifyApprover,
      notifyOnAction,
      notifyWorkflowAccessId: notifyWorkflowAccessId ? notifyWorkflowAccessId.map(obj => { return obj['label'] }) : null,
      conditionType: subProcessCondType,
      tableName: tableName ? tableName.value : null,
      tableColumnName: tableColumnName ? tableColumnName.value : null,
      foreignKeyValue: tableName ? tableName.foreignKeyValue : null,
      columnOperation: columnOperation ? columnOperation.value : null,
      conditionValue: conditionValue
        ? typeof conditionValue === "object"
          ? Array.isArray(conditionValue)
            ? conditionValue.map(obj => { return obj['label'] })
            : conditionValue.label
          : conditionValue
        : null,
      subProcess,
      ddnotifyWorkflowAccessId: notifyWorkflowAccessId,
      ddworkflowAccessId: workflowAccessId,
      ddtableName: tableName,
      ddtableColumnName: tableColumnName,
      ddcolumnOperation: columnOperation,
      ddconditionValue: conditionValue,
    }
    const newData = [...sPDataSource]
    const i = newData.findIndex(_item => _item.id === dataToSave.id);
    if (i > -1) newData[i] = dataToSave;
    else newData.push(dataToSave);
    setSpDataSource(newData)
    toast.success("Step added in table successfully!")
    handleReset()
  }

  /**
* @param e
* function to upsert records of sub-process to main tree-list
**/
  const appendDataToSteps = (e) => {
    const dataForSp = {
      id: dataId ? dataId : dataSource.length + 1,
      sequence: dataId ? dataId : dataSource.length + 1,
      uniqueKey: dataId ? dataId : dataSource.length + 1,
      tableId: tableId ?? null,
      parentId: -1,
      workflowAccessId: null,
      conditionType: "sub-process",
      tableName: null,
      tableColumnName: null,
      columnOperation: null,
      conditionValue: null,
      subProcess: 1,
    }
    const spData = isObjectEmpty(subProcessStep) ? dataForSp : subProcessStep
    let count = spData.id
    sPDataSource.map(obj => {
      obj.workflowStepProcessId = spData?.workflowStepProcessId ?? null
      obj.parentId = spData?.id ?? null
      obj.id = count + 1
      obj.sequence = count + 1
      obj.uniqueKey = count + 1
      count++
      return obj
    })

    if (dataSource.length === 0) {
      toast.error('Please add a step before adding a sub-process')
    } else {
      let orgData = [...dataSource]
      let dObj = _.cloneDeep(dataSource)
      const maxId = Math.max(...sPDataSource.map(o => o.sequence))
      const minId = Math.min(...sPDataSource.map(o => o.id))
      let beforeSpData = dObj.filter(o => o.sequence <= spData.sequence + 1)
      let afterSpData = dObj.filter(o => o.sequence > maxId)

      let aData = _.cloneDeep(afterSpData)
      aData.map(obj => {
        obj.parentId = obj.parentId === -1 ? -1 : obj.parentId + 1
        obj.id = obj.id + 1
        obj.sequence = obj.sequence + 1
        obj.uniqueKey = obj.uniqueKey + 1
        return obj
      })

      const i = orgData.findIndex(_item => _item.id === spData.id);
      if (i > -1) {
        let bData = [...beforeSpData]
        sPDataSource.map(obj => {
          const j = bData.findIndex(_item => _item.id === obj.id);
          if (j > -1) bData[j] = obj;
          else bData.push(obj);
        })
        let updatedData = [...bData, ...aData]
        orgData = updatedData
      } else { orgData.push(spData, ...sPDataSource) }
      data.data.workflow_step = orgData
      setDataSource(orgData)
      updateButtonStateOfParent(true)
      setDataId(null)
      setSubProcessStep({})
      setSpDataSource([])
      setConditionType(null)
      handleReset()
    }
  }

  /**
* @param e
* function to handle validation once records are added to tree list
**/
  const handleSubmit = (e) => {
    if (conditionType === "condition") {
      if (!tableName) {
        toast.error("Please select value")
      } else if (!tableColumnName) {
        toast.error("Please select table column")
      } else if (!columnOperation) {
        toast.error("Please select table column operator")
      } else if (tableColumnName.type !== "BOOLEAN" && !conditionValue) {
        toast.error("Please select condition value")
      } else if (conditionValue && !sanitizeInput(conditionValue)) {
        toast.error("Invalid characters detected. Please remove any special characters.")
      } else {
        setIsDataChanged(true)
        performAction()
      }
    } else if (conditionType === "approver") {
      if (!workflowAccessId) {
        toast.error("Please select approver")
      } else if (notifyOnAction && (!notifyWorkflowAccessId || notifyWorkflowAccessId.length === 0)) {
        toast.error("Please select workflow access to be notified on action!")
      } else {
        setIsDataChanged(true)
        performAction()
      }
    } else if (conditionType === "sub-process") {
      if (subProcessCondType === "condition") {
        if (!tableName) {
          toast.error("Please select value")
        } else if (!tableColumnName) {
          toast.error("Please select table column")
        } else if (!columnOperation) {
          toast.error("Please select table column operator")
        } else if (tableColumnName.type !== "BOOLEAN" && !conditionValue) {
          toast.error("Please select condition value")
        } else if (conditionValue && !sanitizeInput(conditionValue)) {
          toast.error("Invalid characters detected. Please remove any special characters.")
        } else {
          saveToSubProcess()
        }
      } else if (subProcessCondType === "approver") {
        if (!workflowAccessId) {
          toast.error("Please select approver")
        } else if (notifyOnAction && (!notifyWorkflowAccessId || notifyWorkflowAccessId.length === 0)) {
          toast.error("Please select workflow access to be notified on action!")
        } else {
          saveToSubProcess()
        }
      } else {
        saveToSubProcess()
      }
    } else {
      setIsDataChanged(true)
      performAction()
    }
  }

  /**
  * @param e
  * function to empty states on change of main accordion
  **/
  const handleChange = (panel) => (event, newExpanded) => {
    setConditionType(newExpanded ? panel : false);
    setWorkflowAccessId(null)
    setTableName(null)
    setTableColumnName(null)
    setColumnOperation(null)
    setConditionValue(null)
    setSubProcess(0)
    setDataId(null)
    setEditWfStep({})
  };

  /**
  * @param e
  * function to empty states on change of sub-process accordion
  **/
  const handleSPChange = (panel) => (event, newExpanded) => {
    setSubProcessCondType(newExpanded ? panel : false);
    setWorkflowAccessId(null)
    setTableName(null)
    setTableColumnName(null)
    setColumnOperation(null)
    setConditionValue(null)
    setSubProcess(0)
    setDataId(null)
    setEditWfStep({})
  };

  /**
  * @param e
  * function to empty states
  **/
  const handleReset = () => {
    conditionType !== "sub-process" && setConditionType(null)
    setSubProcessCondType(null)
    setWorkflowAccessId(null)
    setTableName(null)
    setTableColumnName(null)
    setColumnOperation(null)
    setConditionValue(null)
    setLabelConditionValue(null)
    setTableId(null)
    setSubProcess(0)
    setDataId(null)
    setParId(null)
    setEditWfStep({})
    setNotifyApprover(true)
    setNotifyOnAction(false)
    setNotifyWorkflowAccessId(null)
  }

  /**
  * @param e
  * function to set sup-process data to sub-process tree list
  **/
  const setEditState = (data, type = null) => {
    setEditWfStep(data)
    if (!isObjectEmpty(data)) {
      setDataId(data.id)
      setConditionType(type ? type : data.conditionType)
      setSubProcessCondType(type ? data.conditionType : null)
      setWorkflowAccessId(data.ddworkflowAccessId)
      setNotifyApprover(data.notifyApprover)
      setNotifyOnAction(data.notifyOnAction)
      setNotifyWorkflowAccessId(data.ddnotifyWorkflowAccessId)
      setTableName(data.ddtableName)
      setTableColumnName(data.ddtableColumnName)
      setColumnOperation(data.ddcolumnOperation)
      setConditionValue(data.ddconditionValue)
      setLabelConditionValue(data.conditionValue)
      setSubProcess(data.subProcess)
      setTableId(data.tableId)
    }
  }

  /**
  * @param e
  * in-built function to handle sequence change of tree list
  **/
  const onDragChange = (e) => {
    const visibleRows = e.component.getVisibleRows();
    const sourceNode = e.component.getNodeByKey(e.itemData.id);
    let targetNode = visibleRows[e.toIndex].node;

    while (targetNode && targetNode.data) {
      if (targetNode.data.id === sourceNode.data.id) {
        e.cancel = true;
        break;
      }
      targetNode = targetNode.parent;
    }
  }

  /**
  * @param e
  * function to handle sequence change of main tree list
  **/
  const onMainReorder = (e) => {
    const visibleRows = e.component.getVisibleRows();
    let sourceData = e.itemData;
    let newData = [...dataSource];
    const sourceIndex = newData.indexOf(sourceData);

    if (e.toIndex === 0 && sourceData.conditionType === "sub-process") {
      toast.error("First step cannot be a sub-process")
    } else {
      if (sourceData.parentId !== -1 && sourceData.conditionType === "sub-process") {
        toast.error("Please modify the sub-process inside the sub-process panel")
      } else {

        if (e.dropInsideItem) {
          sourceData = { ...sourceData, id: visibleRows[e.toIndex].key };
          newData = [
            ...newData.slice(0, sourceIndex),
            sourceData,
            ...newData.slice(sourceIndex + 1),
          ];
        } else {
          const toIndex = e.fromIndex > e.toIndex ? e.toIndex - 1 : e.toIndex;
          let targetData = toIndex >= 0 ? visibleRows[toIndex].node.data : null;

          if (e.fromIndex === 0 && e.toIndex === 1 && targetData.conditionType === "sub-process") {
            toast.error("First step cannot be a sub-process")
          } else {
            if (targetData && e.component.isRowExpanded(targetData.id)) {
              // sourceData = { ...sourceData, parentId: targetData.id };
              // targetData = null;
            } else {
              const parentId = targetData ? targetData.parentId : -1;
              if (sourceData.parentId !== parentId) {
                sourceData = { ...sourceData, parentId: parentId };
              }
            }

            newData = [...newData.slice(0, sourceIndex), ...newData.slice(sourceIndex + 1)];

            const targetIndex = newData.indexOf(targetData) + 1;
            if (sourceData.conditionType === "sub-process") {
              const getChildren = dataSource.filter(obj => obj.parentId === sourceData.id)
              const getAllObj = newData.slice(targetIndex)
              const getAllObjExceptChildren = getAllObj.filter(obj => obj.parentId !== sourceData.id)

              const filter = newData.slice(0, targetIndex).filter(o => o.parentId !== sourceData.id)
              newData = [...filter, sourceData, ...getChildren, ...getAllObjExceptChildren];
              // newData = [...new Map(newData.map(item => [item.id, item])).values()];
            } else {
              newData = [...newData.slice(0, targetIndex), sourceData, ...newData.slice(targetIndex)];
            }
            updateButtonStateOfParent(true)
          }
        }
        data.data.workflow_step = newData
        setDataSource(newData)
      }
    }
  }

  /**
  * @param e
  * function to handle sequence change of sub-process tree list
  **/
  const onSpReorder = (e) => {
    const visibleRows = e.component.getVisibleRows();
    let sourceData = e.itemData;
    let newData = [...sPDataSource];
    const sourceIndex = newData.indexOf(sourceData);

    if (e.dropInsideItem) {
      sourceData = { ...sourceData, id: visibleRows[e.toIndex].key };
      newData = [
        ...newData.slice(0, sourceIndex),
        sourceData,
        ...newData.slice(sourceIndex + 1),
      ];
    } else {
      const toIndex = e.fromIndex > e.toIndex ? e.toIndex - 1 : e.toIndex;
      let targetData = toIndex >= 0 ? visibleRows[toIndex].node.data : null;

      if (targetData && e.component.isRowExpanded(targetData.id)) {
        sourceData = { ...sourceData, parentId: targetData.id };
        targetData = null;
      } else {
        const parentId = targetData ? targetData.parentId : -1;
        if (sourceData.parentId !== parentId) {
          sourceData = { ...sourceData, parentId: parentId };
        }
      }

      newData = [...newData.slice(0, sourceIndex), ...newData.slice(sourceIndex + 1)];

      const targetIndex = newData.indexOf(targetData) + 1;
      newData = [...newData.slice(0, targetIndex), sourceData, ...newData.slice(targetIndex)];
    }

    setSpDataSource(newData)
  }

  /**
  * @param e
  * function to set data from row to the accordion input fields
  **/
  const onFocusedRowChanged = (e) => {
    handleReset()
    const record = e && e.hasOwnProperty('row') ? e.row.node : e.node
    if (e.element.id === "main") {
      if (!record.hasChildren && record.parent.key === -1) {
        setEditState(record.data, null)
      } else {
        if (record.children.length) {
          const newData = []
          const spChild = _.cloneDeep(record.children)
          spChild.map((obj, index) => {
            const newObj = _.cloneDeep(obj.data)
            newObj.pId = newObj.parentId
            delete newObj.parentId
            newObj.id = index + 1
            newObj.sequence = index + 1
            newObj.uniqueKey = index + 1
            newData.push(newObj)
          })
          setConditionType('sub-process')
          setSubProcessStep(record.data)
          setSpDataSource(newData)
        }
      }
    } else if (e.element.id === "sub-process") {
      setEditState(record.data, "sub-process")
    }
  }

  /**
  * @param e
  * function to set condition to allow/disable deleting of row in datagrid
  **/
  const allowDeleting = (e) => {
    return e.row.data.tableId === null && isObjectEmpty(subProcessStep);
  }

  /**
 * @param selectedRowKeys
 * @param selectedRowsData
 * used to get selected rows detail of data-grid
 **/
  const onSelectionChanged = ({ selectedRowKeys, selectedRowsData }) => {
    setSelectedRowKeys(selectedRowsData)
  }

  /**
 * get selected rows
 **/
  const hasSelected = selectedRowKeys.length > 0

  // bulk delete using row selection
  const manageDelete = async () => {

    const result = [...selectedRowKeys];
    const msg = selectedRowKeys.length === dataSource.length ? "You have selected all steps and the workflow associated to it will also be deleted and you won't be able to revert this!" : "You won't be able to revert this!"

    Swal.fire({
      title: 'Are you sure?',
      text: msg,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: "Yes, delete it!",
      heightAuto: false,
      height: '200px',
      customClass: {
        container: '__swal__continer__ __font__family',
        confirmButton: '__default__button__layout',
        cancelButton: '__default__button__layout'
      }
    }).then(async (res) => {
      const newRecords = dataSource.filter(a => !a.tableId)
      let newData = [...dataSource]
      if (res.isConfirmed) {
        const deleteFromApi = selectedRowKeys.filter(a => a.tableId)
        const deleteFromTable = selectedRowKeys.filter(a => !a.tableId)

        if (deleteFromApi.length > 0 && deleteFromTable.length > 0) {

          dispatch({ type: SET_NEW_WF_STEP, payload: {} })

          if (selectedRowKeys.length === dataSource.length) {
            await deleteWorkflowSteps(result, dataSource[0]['workflowId'])
          }
          else {
            await deleteWorkflowSteps(result)
          }
        }
        else if (deleteFromApi && deleteFromApi.length > 0) {

          dispatch({ type: SET_NEW_WF_STEP, payload: {} })
          newWfStep.rows = newRecords
          newWfStep.apiDelete = true

          dispatch({ type: SET_NEW_WF_STEP, payload: newWfStep })

          if (selectedRowKeys.length === dataSource.length) {
            await deleteWorkflowSteps(result, dataSource[0]['workflowId'])
          }
          else {
            await deleteWorkflowSteps(result)
          }
        }
        else if (deleteFromTable && deleteFromTable.length > 0) {
          deleteFromTable.map(a => {
            newData = newData.filter((item) => item.id !== a.id);
          })
          data.data.workflow_step = newData
          data.data.dataChanged = true
          setDataSource(data.data.workflow_step)
        }
        setSelectedRowKeys([]);
        if (deleteFromApi && deleteFromApi.length) await fetchFromApi(valueToFetch)
      }
      setSelectedRowKeys([]);
    })
  }

  /**
  * @param records
  * function to implement select all functionality for multi-select dropdown
  **/
  function getOptions(records) {
    return records && records.length > 0 ?
      [{ label: "Select All", value: "all" }, ...records] : []
  }

  /**
 * custom function using useMemo to avoid re-renders unless the states listed are changed
 **/
  const Comp = useMemo(() => {
    return (
      <React.Fragment>
        <form onSubmit={(e) => { e.preventDefault() }}>
          <MDBox>
            <MDBox pt={2} pl={3} pr={3} pb={3}>
              <div>
                <Accordion expanded={conditionType === 'condition'} onChange={handleChange('condition')}>
                  <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                    <MDTypography style={{ color: "#333333", fontSize: "14px" }} variant="h6" >By Condition</MDTypography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <MDBox mt={2}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                          <Select options={data.data.workflowStepTableData && data.data.workflowStepTableData.tableData && data.data.workflowStepTableData.tableData.length > 0 ? data.data.workflowStepTableData.tableData : []}
                            isDisabled={permissions && !permissions.canCreate}
                            placeholder="Please Select Table..." isClearable={true}
                            value={tableName}
                            styles={selectCustomStyles} isSearchable
                            menuPortalTarget={document.body}
                            onChange={(val) => {
                              setTableName(val)
                              setTableColumnName(null)
                              setColumnOperation(null)
                              setConditionValue(null)
                            }} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Select options={tableName ? tableName.tableColumns : []} isClearable={true} value={tableColumnName}
                            placeholder="Please Select Column..." styles={selectCustomStyles} isSearchable menuPortalTarget={document.body} isDisabled={!tableName && !editWfStep.hasOwnProperty('ddtableName')}
                            onChange={(val) => {
                              setTableColumnName(val)
                              setColumnOperation(null)
                              setConditionValue(null)
                            }} />
                        </Grid>
                      </Grid>
                    </MDBox>
                    <MDBox mt={2}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                          <Select options={tableColumnName
                            ? data.data.workflowStepTableData && data.data.workflowStepTableData.dropdownData && data.data.workflowStepTableData.dropdownData.tableColumnOperators
                              ? data.data.workflowStepTableData.dropdownData.tableColumnOperators[tableColumnName.type] : [] : null}
                            placeholder="Please Select Operator..." isClearable={true} value={columnOperation} styles={selectCustomStyles} isSearchable
                            menuPortalTarget={document.body} isDisabled={!tableColumnName}
                            onInputChange={() => { }}
                            onChange={async (val) => {
                              setColumnOperation(val)
                              setConditionValue(null)
                              if (tableColumnName && tableColumnName.type === "STRING" && (val.value === "=" || val.value === "in")) {
                                await getRecordByField(tableName.modelName, tableColumnName.name)
                              }
                            }} />

                        </Grid>
                        <Grid item xs={12} sm={6}>
                          {
                            columnOperation && tableColumnName && tableColumnName.type && tableColumnName.type === "STRING"
                              ? columnOperation && columnOperation.value && columnOperation.value === "="
                                ? <Select options={records ? records : []} isClearable={true} value={conditionValue} styles={selectCustomStyles} isSearchable
                                  isLoading={isLoading}
                                  placeholder="Please Select Value..." menuPortalTarget={document.body}
                                  onChange={(val) => {
                                    setConditionValue(val)
                                  }} />
                                : columnOperation && columnOperation.value && columnOperation.value === "in"
                                  ? <Select options={getOptions(records)} value={conditionValue} defaultValue={conditionValue}
                                    isLoading={isLoading}
                                    styles={selectCustomStyles}
                                    isMulti={true}
                                    isSearchable
                                    menuPortalTarget={document.body}
                                    onChange={val => {
                                      if (val.length && val.find(option => option.value === "all")) {
                                        if (records && records && records.length > 0) {
                                          const r = [...getOptions(records).slice(1), ...records]
                                          const fr = [...new Map(r?.map(item => [item['id'], item])).values()]
                                          setConditionValue(fr)
                                          return fr
                                        }
                                        // const r = getOptions(records).slice(1)
                                        // setConditionValue(r)
                                        // return val
                                      }
                                      setConditionValue(val)
                                      return val
                                    }}
                                  />
                                  : <FormField isForWF={true} label={`Please enter ${tableColumnName.label}`} value={conditionValue}
                                    onChange={e => {
                                      const { value: inputValue } = e.target;
                                      setConditionValue(inputValue)
                                    }} />
                              : columnOperation && tableColumnName && tableColumnName.type && tableColumnName.type === "NUMBER"
                                ? <FormField isForWF={true} type={tableColumnName && tableColumnName.type && tableColumnName.type === "NUMBER" ? 'number' : null}
                                  label={`Please enter ${tableColumnName.label}`} value={conditionValue}
                                  onChange={e => {
                                    const { value: inputValue } = e.target;
                                    setConditionValue(inputValue)
                                  }} />
                                : null
                          }
                        </Grid>
                      </Grid>
                    </MDBox>
                    <MDBox display="flex" justifyContent="right" alignItems="center" position="relative" zIndex={1} pt={3}>
                      <DEButton text="Add" disabled={!tableName} onClick={handleSubmit} useSubmitBehavior={true} stylingMode="contained" type="default" style={{ marginRight: "5px" }} />
                      <DEButton text="Reset" disabled={!tableName} onClick={handleReset} stylingMode="contained" type="danger" />
                    </MDBox>
                  </AccordionDetails>
                </Accordion>
                <Accordion expanded={conditionType === 'approver'} onChange={handleChange('approver')}>
                  <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                    <MDTypography style={{ color: "#333333", fontSize: "14px" }} variant="h6" >By Approver</MDTypography>
                  </AccordionSummary>
                  <AccordionDetails>

                    <MDBox mt={2} mb={2}>
                      <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }} alignItems="center">
                        <Grid item xs={6}>
                          <Select options={data.data.workflowStepTableData && data.data.workflowStepTableData.aproverRoleData && data.data.workflowStepTableData.aproverRoleData.length > 0 ? data.data.workflowStepTableData.aproverRoleData : []}
                            placeholder="Please Select Approver..." isClearable={true} value={workflowAccessId} styles={selectCustomStyles} isSearchable
                            isDisabled={permissions && !permissions.canCreate} menuPortalTarget={document.body}
                            onChange={(val) => { setWorkflowAccessId(val) }} />
                        </Grid>
                        <Grid item xs={6}>
                          <Grid container rowSpacing={1} alignItems="center">
                            <Grid item xs={1}>
                              <Switch checked={notifyApprover} disabled={!workflowAccessId}
                                onChange={(c) => {
                                  setNotifyApprover(wasOpened => wasOpened ? false : true)
                                }}
                              />
                            </Grid>
                            <Grid item xs={1}>
                              <MDTypography variant="subtitle">
                                Notify for Action Required
                              </MDTypography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={6}>
                          <Grid container rowSpacing={1} alignItems="center">
                            <Grid item xs={1}>
                              <Switch checked={notifyOnAction} disabled={!workflowAccessId}
                                onChange={(c) => {
                                  setNotifyOnAction(wasOpened => wasOpened ? false : true)
                                  if (!c) {
                                    setNotifyWorkflowAccessId(null)
                                  }
                                }}
                              />
                            </Grid>
                            <Grid item xs={1}>
                              <MDTypography variant="subtitle">
                                Alert upon action
                              </MDTypography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={6}>
                          {
                            notifyOnAction
                              ? <Select options={data.data.workflowStepTableData && data.data.workflowStepTableData.aproverRoleData && data.data.workflowStepTableData.aproverRoleData.length > 0 ? data.data.workflowStepTableData.aproverRoleData : []}
                                value={notifyWorkflowAccessId} defaultValue={notifyWorkflowAccessId}
                                isLoading={isLoading}
                                styles={selectCustomStyles}
                                isMulti={true}
                                isSearchable
                                menuPortalTarget={document.body}
                                onChange={val => {
                                  setNotifyWorkflowAccessId(val)
                                }}
                              />
                              : null
                          }
                        </Grid>
                      </Grid>
                    </MDBox>

                    <MDBox display="flex" justifyContent="right" alignItems="center" position="relative" zIndex={1} >
                      <DEButton text="Add" disabled={!tableName && !workflowAccessId} onClick={handleSubmit} useSubmitBehavior={true} stylingMode="contained" type="default" style={{ marginRight: "5px" }} />
                      <DEButton text="Reset" disabled={!tableName && !workflowAccessId} onClick={handleReset} stylingMode="contained" type="danger" />
                    </MDBox>
                  </AccordionDetails>
                </Accordion>
                <Accordion /* disabled={dataSource.length === 0} */ expanded={conditionType === 'sub-process'} onChange={handleChange('sub-process')}>
                  <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                    <MDTypography style={{ color: "#333333", fontSize: "14px" }} variant="h6" >By Sub Process</MDTypography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Accordion expanded={subProcessCondType === 'condition'} onChange={handleSPChange('condition')}>
                      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                        <MDTypography style={{ color: "#333333", fontSize: "14px" }} variant="h6" >By Condition</MDTypography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <MDBox mt={2}>
                          <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                              <Select options={data.data.workflowStepTableData && data.data.workflowStepTableData.tableData && data.data.workflowStepTableData.tableData.length > 0 ? data.data.workflowStepTableData.tableData : []}
                                placeholder="Please Select Table..." isClearable={true}
                                value={tableName}
                                styles={selectCustomStyles} isSearchable
                                menuPortalTarget={document.body} isDisabled={permissions && !permissions.canCreate}
                                onChange={(val) => {
                                  setTableName(val)
                                  setTableColumnName(null)
                                  setColumnOperation(null)
                                  setConditionValue(null)
                                }} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Select options={tableName ? tableName.tableColumns : []} isClearable={true} value={tableColumnName}
                                placeholder="Please Select Column..." styles={selectCustomStyles} isSearchable menuPortalTarget={document.body} isDisabled={!tableName && !editWfStep.hasOwnProperty('ddtableName')}
                                onChange={(val) => {
                                  setTableColumnName(val)
                                  setColumnOperation(null)
                                  setConditionValue(null)
                                }} />
                            </Grid>
                          </Grid>
                        </MDBox>
                        <MDBox mt={2}>
                          <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                              <Select options={tableColumnName
                                ? data.data.workflowStepTableData && data.data.workflowStepTableData.dropdownData && data.data.workflowStepTableData.dropdownData.tableColumnOperators
                                  ? data.data.workflowStepTableData.dropdownData.tableColumnOperators[tableColumnName.type] : [] : null}
                                placeholder="Please Select Operator..." isClearable={true} value={columnOperation} styles={selectCustomStyles} isSearchable
                                menuPortalTarget={document.body} isDisabled={!tableColumnName}
                                onChange={async (val) => {
                                  setColumnOperation(val)
                                  setConditionValue(null)
                                  if (tableColumnName && tableColumnName.type === "STRING" && (val.value === "=" || val.value === "in")) {
                                    await getRecordByField(tableName.modelName, tableColumnName.name)
                                  }
                                }} />

                            </Grid>
                            <Grid item xs={12} sm={6}>
                              {
                                columnOperation && tableColumnName && tableColumnName.type && tableColumnName.type === "STRING"
                                  ? columnOperation && columnOperation.value && columnOperation.value === "="
                                    ? <Select options={records ? records : []} isClearable={true} value={conditionValue} styles={selectCustomStyles} isSearchable
                                      placeholder="Please Select Value..." menuPortalTarget={document.body}
                                      onChange={(val) => {
                                        setConditionValue(val)
                                      }} />
                                    : columnOperation && columnOperation.value && columnOperation.value === "in"
                                      ? <Select options={getOptions(records)} value={conditionValue} defaultValue={conditionValue}
                                        styles={selectCustomStyles}
                                        isMulti={true}
                                        isSearchable
                                        menuPortalTarget={document.body}
                                        onChange={val => {
                                          if (val.length && val.find(option => option.value === "all")) {
                                            if (records && records && records.length > 0) {
                                              const r = [...getOptions(records).slice(1), ...records]
                                              const fr = [...new Map(r?.map(item => [item['id'], item])).values()]
                                              setConditionValue(fr)
                                              return fr
                                            }
                                            // const r = getOptions(records).slice(1)
                                            // setConditionValue(r)
                                            // return val
                                          }
                                          setConditionValue(val)
                                          return val
                                          // setConditionValue(val)
                                        }}
                                      />
                                      : <FormField isForWF={true} label={`Please enter ${tableColumnName.label}`} value={conditionValue}
                                        onChange={e => {
                                          const { value: inputValue } = e.target;
                                          setConditionValue(inputValue)
                                        }} />
                                  : columnOperation && tableColumnName && tableColumnName.type && tableColumnName.type === "NUMBER"
                                    ? <FormField isForWF={true} type={'number'}
                                      label={`Please enter ${tableColumnName.label}`} value={conditionValue}
                                      onChange={e => {
                                        const { value: inputValue } = e.target;
                                        setConditionValue(inputValue)
                                      }} />
                                    : null
                              }
                            </Grid>
                          </Grid>
                        </MDBox>
                        <MDBox display="flex" justifyContent="right" alignItems="center" position="relative" zIndex={1} pt={3}>
                          <DEButton text="Add" disabled={!tableName} onClick={handleSubmit} useSubmitBehavior={true} stylingMode="contained" type="default" style={{ marginRight: "5px" }} />
                          <DEButton text="Reset" disabled={!tableName} onClick={handleReset} stylingMode="contained" type="danger" />
                        </MDBox>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={subProcessCondType === 'approver'} onChange={handleSPChange('approver')}>
                      <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                        <MDTypography style={{ color: "#333333", fontSize: "14px" }} variant="h6" >By Approver</MDTypography>
                      </AccordionSummary>
                      <AccordionDetails>

                        <MDBox mt={2} mb={2}>
                          <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }} alignItems="center">
                            <Grid item xs={6}>
                              <Select options={data.data.workflowStepTableData && data.data.workflowStepTableData.aproverRoleData && data.data.workflowStepTableData.aproverRoleData.length > 0 ? data.data.workflowStepTableData.aproverRoleData : []}
                                placeholder="Please Select Approver..." isClearable={true} value={workflowAccessId} styles={selectCustomStyles} isSearchable
                                isDisabled={permissions && !permissions.canCreate} menuPortalTarget={document.body}
                                onChange={(val) => { setWorkflowAccessId(val) }} />
                            </Grid>
                            <Grid item xs={6}>
                              <Grid container rowSpacing={1} alignItems="center">
                                <Grid item xs={1}>
                                  <Switch checked={notifyApprover} disabled={!workflowAccessId}
                                    onChange={(c) => {
                                      setNotifyApprover(wasOpened => wasOpened ? false : true)
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={1}>
                                  <MDTypography variant="subtitle">
                                    Notify for Action Required
                                  </MDTypography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={6}>
                              <Grid container rowSpacing={1} alignItems="center">
                                <Grid item xs={1}>
                                  <Switch checked={notifyOnAction} disabled={!workflowAccessId}
                                    onChange={(c) => {
                                      setNotifyOnAction(wasOpened => wasOpened ? false : true)
                                      if (!c) {
                                        setNotifyWorkflowAccessId(null)
                                      }
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={1}>
                                  <MDTypography variant="subtitle">
                                    Alert upon action
                                  </MDTypography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={6}>
                              {
                                notifyOnAction
                                  ? <Select
                                    options={data.data.workflowStepTableData && data.data.workflowStepTableData.aproverRoleData && data.data.workflowStepTableData.aproverRoleData.length > 0 ? data.data.workflowStepTableData.aproverRoleData : []}
                                    value={notifyWorkflowAccessId} defaultValue={notifyWorkflowAccessId}
                                    isLoading={isLoading}
                                    styles={selectCustomStyles}
                                    isMulti={true}
                                    isSearchable
                                    menuPortalTarget={document.body}
                                    onChange={val => {
                                      setNotifyWorkflowAccessId(val)
                                    }}
                                  />
                                  : null
                              }
                            </Grid>
                          </Grid>
                        </MDBox>

                        <MDBox display="flex" justifyContent="right" alignItems="center" position="relative" zIndex={1} >
                          <DEButton text="Add" disabled={!tableName && !workflowAccessId} onClick={handleSubmit} useSubmitBehavior={true} stylingMode="contained" type="default" style={{ marginRight: "5px" }} />
                          <DEButton text="Reset" disabled={!tableName && !workflowAccessId} onClick={handleReset} stylingMode="contained" type="danger" t />
                        </MDBox>
                      </AccordionDetails>
                    </Accordion>
                  </AccordionDetails>
                  <MDBox pt={2} pb={3} pl={3} pr={3}>
                    <div>
                      <TreeList id="sub-process" dataSource={sPDataSource} rootValue={-1} keyExpr="id" showRowLines={true} showBorders={true}
                        parentIdExpr="parentId" defaultExpandedRowKeys={expandedRowKeys} columnAutoWidth={true} focusedRowEnabled={true}
                        onRowRemoved={e => { handleReset() }} onRowDblClick={onFocusedRowChanged}>
                        <RowDragging onDragChange={onDragChange} onReorder={onSpReorder} allowDropInsideItem={false} allowReordering={true} showDragIcons={true} />
                        <Editing allowDeleting={allowDeleting} />
                        <Column dataField="conditionType" caption="Step Type" />
                        <Column dataField="workflowAccessId" caption="Approver" />
                        <Column dataField="tableName" />
                        <Column dataField="tableColumnName" />
                        <Column dataField="columnOperation" />
                        <Column dataField="conditionValue" />
                        <Column dataField="notifyApprover" />
                        <Column dataField="notifyOnAction" caption="Alert upon action" />
                        <Column dataField="notifyWorkflowAccessId" caption="Alert Roles" />
                        <Column type="buttons" caption={"Actions"}>
                          <Button name="delete" icon={'trash'} />
                        </Column>
                      </TreeList>
                    </div>
                  </MDBox>
                  <MDBox display="flex" justifyContent="right" alignItems="center" position="relative" zIndex={1} pb={2} pr={3}>
                    <DEButton text="Add To Steps" disabled={sPDataSource.length === 0} onClick={appendDataToSteps} stylingMode="contained" type="default" style={{ marginRight: "5px" }} />
                    <DEButton text="Reset" disabled={sPDataSource.length === 0} onClick={() => { setSpDataSource([]) }} stylingMode="contained" type="danger" />
                  </MDBox>
                </Accordion>
              </div>
            </MDBox>
          </MDBox>
        </form>

        {selectedRowKeys.length > 0
          ?
          <>
            <br />
            <MDAlert color="light">

              <MDTypography variant="subtitle2">
                {`Selected ${selectedRowKeys.length} ${selectedRowKeys.length === 1 ? "item" : "items"}`}
              </MDTypography>

              <Divider orientation="vertical" color="dark" flexItem />

              <MDBox>
                <Grid container spacing={2}>
                  {
                    permissions && permissions.canDelete
                      ? <Grid item >
                        <DEButton hint="Delete" icon="trash" onClick={() => { manageDelete() }} stylingMode="contained" type="danger" />
                      </Grid>
                      : null
                  }
                </Grid>
              </MDBox>
            </MDAlert>
          </>
          : ""
        }

        <div>
          <TreeList id="main" /* loadPanel={isLoading} disabled={isLoading} */ dataSource={dataSource} rootValue={-1} keyExpr="id" showRowLines={true} showBorders={true} parentIdExpr="parentId"
            defaultExpandedRowKeys={expandedRowKeys} wordWrapEnabled={true} focusedRowEnabled={true} onSelectionChanged={onSelectionChanged}
            onRowRemoved={() => { }} columnAutoWidth={true} onRowDblClick={permissions && permissions.canCreate ? onFocusedRowChanged : null} repaintChangesOnly={true}>
            <Editing allowDeleting={allowDeleting} />
            <Selection recursive={"all"} allowSelectAll={true} mode="multiple" />
            <RowDragging onDragChange={onDragChange} onReorder={permissions && permissions.canCreate ? onMainReorder : null} allowDropInsideItem={false} allowReordering={true} showDragIcons={true} />
            <Scrolling showScrollbar="always" mode="standard" />
            <Column dataField="conditionType" caption="Step Type" />
            <Column dataField="workflowAccessId" caption="Approver" />
            <Column dataField="tableName" />
            <Column dataField="tableColumnName" />
            <Column dataField="columnOperation" />
            <Column dataField="conditionValue" />
            <Column dataField="notifyApprover" />
            <Column dataField="notifyOnAction" caption="Alert upon action" />
            <Column dataField="notifyWorkflowAccessId" caption="Alert Roles" />
            <Column type="buttons" caption={"Actions"}>
              <Button name="delete" icon={'trash'} />
            </Column>
          </TreeList>
        </div>
      </React.Fragment>
    )
  }, [conditionType, subProcessCondType, workflowAccessId, notifyWorkflowAccessId,
    tableName, tableColumnName, columnOperation, conditionValue,
    labelConditionValue, tableId, subProcess, dataId, parId, dropDownData,
    dataSource, sPDataSource, subProcessStep, editWfStep, records, notifyOnAction,
    isLoading, workflow, data, isDataChanged, hasSelected, selectedRowKeys, notifyApprover])

  return (
    <React.Fragment>
      {Comp}
    </React.Fragment>
  );
};

export default WorkflowStep;