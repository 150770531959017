export function groupBy(data, keys) {
  let map = {};

  // First pass: create all nodes and map them by their identifiers
  for (let node of data) {
    let identifier = "";
    for (let key of keys) {
      if (node[key] === undefined) break;
      identifier += (node[key] || "") + ":";
    }
    map[identifier] = { ...node, value: identifier, children: [] };
  }

  // Second pass: add each node to its parent's children array
  let roots = [];
  for (let identifier in map) {
    let node = map[identifier];
    let parentIdentifier = identifier.split(":");
    parentIdentifier.pop();
    parentIdentifier.pop();
    parentIdentifier = parentIdentifier.join(":") + ":";

    if (map[parentIdentifier]) {
      // Check if the child with the same 'id' already exists
      let duplicateChildExists = map[parentIdentifier].children.some(
        (child) => child.id === node.id
      );

      if (!duplicateChildExists) {
        map[parentIdentifier].children.push(node);
      }
    } else {
      // Check if highest hierarchical key is null
      let highestKey = keys[Math.min(keys.length, identifier.split(":").length - 2)];
      if (node[highestKey] !== null && highestKey === keys[0]) {
        roots.push(node);
      }
    }
  }

  // Iterate over the nodes and remove the 'children' property if it's empty
  for (let identifier in map) {
    let node = map[identifier];
    if (node.children.length === 0) {
      delete node.children;
    }
  }

  return roots;
}

export const flattenData = (
  data,
  masterOrgDivisionId = null,
  masterOrgEntityId = null,
  masterCountryId = null,
  masterOrgVerticalId = null,
  masterOrgFunctionId = null,
  masterLevelId = null,
  masterDepartmentId = null,
  masterClientId = null,
  masterFCAgencyId = null,
  masterFCEntityId = null,
  masterFCDetailTypeId = null,
  masterFCBudgetLineId = null,
  transactionId = null,
  dataAccessCombinationId = null,
  orgDataAccessId = null
) => {
  let result = [];
  for (let item of data) {
    let current = {
      transactionId: item.transactionId || transactionId,
      masterOrgDivisionId: item.masterOrgDivisionId || masterOrgDivisionId,
      masterOrgEntityId: item.masterOrgEntityId || masterOrgEntityId,
      masterOrgVerticalId: item.masterOrgVerticalId || masterOrgVerticalId,
      masterOrgFunctionId: item.masterOrgFunctionId || masterOrgFunctionId,
      masterCountryId: item.masterCountryId || masterCountryId,
      masterLevelId: item.masterLevelId || masterLevelId,
      masterDepartmentId: item.masterDepartmentId || masterDepartmentId,
      masterClientId: item.masterClientId || masterClientId,
      masterFCAgencyId: item.masterFCAgencyId || masterFCAgencyId,
      masterFCEntityId: item.masterFCEntityId || masterFCEntityId,
      masterFCDetailTypeId: item.masterFCDetailTypeId || masterFCDetailTypeId,
      masterFCBudgetLineId: item.masterFCBudgetLineId || masterFCBudgetLineId,
      dataAccessCombinationId: item.dataAccessCombinationId || dataAccessCombinationId,
      orgDataAccessId: item.orgDataAccessId || orgDataAccessId,
    };
    result.push(current);
    if (item.children) {
      result = result.concat(
        flattenData(
          item.children,
          current.masterOrgDivisionId,
          current.masterOrgEntityId,
          current.masterCountryId,
          current.masterOrgVerticalId,
          current.masterOrgFunctionId,
          current.masterLevelId,
          current.masterDepartmentId,
          current.masterClientId,
          current.masterFCAgencyId,
          current.masterFCEntityId,
          current.masterFCDetailTypeId,
          current.masterFCBudgetLineId,
          current.transactionId,
          current.dataAccessCombinationId,
          current.orgDataAccessId
        )
      );
    }
  }
  return result;
};

export function mapToObj(inputMap) {
  let obj = [];

  inputMap.forEach(function (value, key) {
    if (value instanceof Map) {
      obj.push(mapToObj(value));
    } else if (value && typeof value === "object" && value.combinations instanceof Map) {
      obj.push({ ...value, combinations: mapToObj(value.combinations) });
    } else {
      obj.push(value);
    }
  });

  return obj;
}

export function objToMap(obj) {
  const entries = Object.entries(obj).map(([k, v]) => {
    if (v && typeof v === "object" && v.combinations instanceof Object) {
      return [k, { ...v, combinations: objToMap(v.combinations) }];
    } else {
      return [k, v];
    }
  });
  return new Map(entries);
}

export const getNodesByValues = (nodes, values) => {
  let foundNodes = [];
  for (const node of nodes) {
    if (values.includes(node.value)) {
      foundNodes.push(node);
    }
    if (Array.isArray(node.children)) {
      const childNodes = getNodesByValues(node.children, values);
      foundNodes = foundNodes.concat(childNodes);
    }
  }
  return foundNodes;
};
