export const validateAgencyEntityTypeBudget = (value, prevValues, userDataAccess) => {
  const { masterFCAgencyId, masterFCEntityId, masterFCDetailTypeId, masterFCBudgetLineId } =
    value.formula;

  if (masterFCAgencyId) strategies.agencySelected(value, prevValues, userDataAccess);
  if (masterFCEntityId) strategies.entitySelected(value, prevValues, userDataAccess);
  if (masterFCDetailTypeId) strategies.detailTypeSelected(value, prevValues, userDataAccess);
  if (masterFCBudgetLineId) strategies.budgetLineSelected(value, prevValues, userDataAccess);

  return prevValues;
};

const strategies = {
  agencySelected: (value, prevValues, userDataAccess) => {
    const divisionIds = value.transactions.map((e) => e.masterFCAgencyId);
    prevValues["masterFCAgencyId"] = divisionIds;
  },

  entitySelected: (value, prevValues, userDataAccess) => {
    const entityIds = value.transactions.map((e) => e.masterFCEntityId);
    prevValues["masterFCEntityId"] = entityIds;
    prevValues["masterFCAgencyId"] = filterAndMap(
      userDataAccess["masterFCEntityId"],
      "masterFCEntityId",
      "masterFCAgencyId",
      entityIds
    );
  },

  detailTypeSelected: (value, prevValues, userDataAccess) => {

    const verticalIds = value.transactions.map((e) => e.masterFCDetailTypeId);
    let filteredVerticals = userDataAccess["masterFCDetailTypeId"].filter((e) =>
      verticalIds.includes(e["masterFCDetailTypeId"])
    );

    if (value.formula.masterFCEntityId) {
      const entityIds = value.transactions.map((e) => e.masterFCEntityId);
      filteredVerticals = filteredVerticals.filter((e) => entityIds.includes(e.masterFCEntityId));
    }
    else if (value.formula.masterFCAgencyId) {
      const divisionIds = value.transactions.map((e) => e.masterFCAgencyId);
      filteredVerticals = filteredVerticals.filter((e) =>
        divisionIds.includes(e.masterFCAgencyId)
      );
    }
    if (!value.formula.masterFCEntityId) prevValues["masterFCEntityId"] = filteredVerticals.map((e) => e.masterFCEntityId);
    if (!value.formula["masterFCAgencyId"]) prevValues["masterFCAgencyId"] = filteredVerticals.map((e) => e.masterFCAgencyId);
    prevValues["masterFCDetailTypeId"] = filteredVerticals.map((e) => e.masterFCDetailTypeId);
  },

  budgetLineSelected: (value, prevValues, userDataAccess) => {

    const functionsIds = value.transactions.map((e) => e.masterFCBudgetLineId);

    const allFunctions = userDataAccess["masterFCBudgetLineId"].filter((e) =>
      functionsIds.includes(e.masterFCBudgetLineId)
    );
    let verticalIds = [];
    if (value.formula.masterFCDetailTypeId) verticalIds = value.transactions.map((e) => e.masterFCDetailTypeId);
    else verticalIds = allFunctions.map((e) => e.masterFCDetailTypeId);

    let filteredVerticals = userDataAccess["masterFCDetailTypeId"].filter((e) =>
      verticalIds.includes(e["masterFCDetailTypeId"])
    );

    if (value.formula.masterFCEntityId)
    {
      const entityIds = value.transactions.map((e) => e.masterFCEntityId);
      filteredVerticals = filteredVerticals.filter((e) => entityIds.includes(e.masterFCEntityId));
    }
    else if (value.formula.masterFCAgencyId)
    {
      const divisionIds = value.transactions.map((e) => e.masterFCAgencyId);
      filteredVerticals = filteredVerticals.filter((e) =>
        divisionIds.includes(e.masterFCAgencyId)
      );
    }

    prevValues["masterFCDetailTypeId"] = filteredVerticals.map((e) => e.masterFCDetailTypeId);

    let filteredFunctions = userDataAccess["masterFCDetailTypeId"].filter((e) =>
      prevValues["masterFCDetailTypeId"].includes(e["masterFCDetailTypeId"])
    );

    if (value.formula.masterFCEntityId) {
      const entityIds = value.transactions.map((e) => e.masterFCEntityId);
      filteredFunctions = filteredFunctions.filter((e) => entityIds.includes(e.masterFCEntityId));
    }
    else if (value.formula.masterFCAgencyId) {
      const divisionIds = value.transactions.map((e) => e.masterFCAgencyId);
      filteredFunctions = filteredFunctions.filter((e) =>
        divisionIds.includes(e.masterFCAgencyId)
      );
    }

    prevValues["masterFCBudgetLineId"] = filteredFunctions.map((e) => e.masterFCBudgetLineId);
    prevValues["masterFCEntityId"] = filteredFunctions.map((e) => e.masterFCEntityId);
    prevValues["masterFCAgencyId"] = filteredFunctions.map((e) => e.masterFCAgencyId);

  },
};

const filterAndMap = (data, filterField, mapField, filterValues) =>
  data.filter((e) => filterValues.includes(e[filterField])).map((e) => e[mapField]);
