import React, { useEffect, useContext } from 'react';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import Select from 'react-select';
import DataAccessCombination from './DataAccessCombination';
import Swal from 'sweetalert2';
import {
  selectCustomStyles,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  DEButton
} from 'utils/services/Helpers';
import { DataAccessContext } from './contexts/data-access-context';
import { v4 as uuidv4 } from 'uuid';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

const MASTER_ID_MAP = {
  masterOrgDivisionId: 'Division',
  masterOrgEntityId: 'Entity',
  masterOrgVerticalId: 'Vertical',
  masterOrgFunctionId: 'Function',
  masterCountryId: 'Country',
  masterLevelId: 'Level',
  masterDepartmentId: 'Departments',
  masterClientId: 'Clients',
  masterFCAgencyId: 'Agency',
  masterFCEntityId: 'FCEntities',
  masterFCDetailTypeId: 'Revenue',
  masterFCBudgetLineId: 'Budget Line',
};

const DataAccessAccordion = ({
  userDataAccess,
  combinationsData,
  dataAccessFormulasData,
  accordionID,
  handleRemoveAccordion,
  deleteDataAccessCombination,
  userId,
  masterModuleId
}) => {
  const {
    workflowApprovalList,
    dataAccesses,
    handleAddCombination,
    handleUpdateDataAccess,
    removeFromNestedMap,
    getDataAccessValue,
    setSelectedWorkFlowApprovals,
    setDataAccessValidations,
    handleUpdateCombination,
    getCombination,
    removeKeyDataValidation,
    getSelectedApprovals
  } = useContext(DataAccessContext);

  useEffect(() => {
    if (combinationsData.length) {
      if (!getDataAccessValue(accordionID)?.combinations) addCombination();
    }
  }, [combinationsData]);

  const addCombination = () => {
    handleAddCombination(accordionID, uuidv4(), {});
  };

  const handleRemoveCombination = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'This will delete this combination',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      heightAuto: false,
      height: '200px',
      customClass: {
        container: '__swal__continer__ __font__family',
        confirmButton: '__default__button__layout',
        cancelButton: '__default__button__layout'
      }
    }).then(async (result) => {
      if (result.isConfirmed) {
        removeKeyDataValidation(accordionID, id, 'transactions');
        removeKeyDataValidation(accordionID, id, 'selectedTypes');
        if (combinationsData.length) {
          setDataAccessValidations((prevDataAccessValidations) => ({
            ...prevDataAccessValidations,
            combinationsData
          }));
        }
        const dataAccessCombination = getCombination(accordionID, id);
        if (dataAccessCombination.id) {
          removeFromNestedMap(accordionID, id);
          await deleteDataAccessCombination(
            dataAccessCombination.dataAccessId,
            dataAccessCombination.id,
            userId,
            masterModuleId
          );
          return;
        }
        removeFromNestedMap(accordionID, id);
      }
    });
  };

  const handleWorkFlowApprovalChange = (value) => {
    handleUpdateDataAccess(accordionID, { wfApproval: value });
    setSelectedWorkFlowApprovals((prevValue) => {
      return [...prevValue, value];
    });
  };

  const handleSubmitCombination = (key, submitted) => {
    handleUpdateCombination(accordionID, key, { submitted });
  };

  const handleEditButtonClicked = (key, value, accordionID) => {
    // check if type is HFMS or the combination doesn't have a type
    if (!value?.type?.value || value?.type?.value === 'HFMS' || value?.type?.value === 'market' && value?.type?.value === 'master_employee' && value?.type?.value === 'master_departments' && value?.type?.value === 'master_client') {
      handleSubmitCombination(key, false);
      return;
    }
    // get the combinations Map
    const combinationsMap = dataAccesses.get(accordionID)?.combinations;
    // check if the combination is the last item in the map
    if (!isKeyNotLastInMap(combinationsMap, key)) {
      handleSubmitCombination(key, false);
      return;
    }
    // get the combination transactions size
    const transactions = getCombination(accordionID, key)?.transactions?.length;
    // check if the transactions are empty
    if (!transactions) {
      handleSubmitCombination(key, false);
      return;
    }

    Swal.fire({
      title: 'Are you sure?',
      text: 'Changes here will reset all the dependent combinations',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      heightAuto: false,
      height: '200px',
      customClass: {
        container: '__swal__continer__ __font__family',
        confirmButton: '__default__button__layout',
        cancelButton: '__default__button__layout'
      }
    }).then(async (result) => {
      if (result.isConfirmed) {
        handleSubmitCombination(key, false);
      }
    });
  };

  function isKeyNotLastInMap(map, key) {
    let found = false;
    for (let [otherKey] of map) {
      if (found) return true; // if 'found' is true, that means the current key is not the last one
      if (otherKey === key) found = true;
    }
    return false;
  }

  return (
    <MDBox pb={3} key={accordionID}>
      <Accordion defaultExpanded={dataAccesses ? dataAccesses.size < 2 : true}>
        <AccordionSummary
          aria-controls={`panel${accordionID}d-content`}
          id={`panel${accordionID}d-header`}
        >
          <MDBox pt={0.8} pr={2}>
            <MDTypography variant="h6" style={{ color: "#333333", fontSize: "14px" }}>Data Access By</MDTypography>
          </MDBox>
          {workflowApprovalList?.length > 0 && (
            <MDBox onClick={(e) => e.stopPropagation()} width={350}>
              <Select
                placeholder={'Please select workflow approver...'}
                isClearable
                isRtl={false}
                styles={selectCustomStyles}
                onChange={handleWorkFlowApprovalChange}
                value={getDataAccessValue(accordionID)?.wfApproval ?? null}
                options={workflowApprovalList.filter((e) => !getSelectedApprovals().includes(e))}
              />
            </MDBox>
          )}
          <MDBox
            style={{ position: 'absolute', right: '16px' }}
            onClick={(e) => e.stopPropagation()}
          >
            <DEButton
              stylingMode={'contained'}
              type={'danger'}
              hint="Remove Data Access"
              icon="trash"
              onClick={() => handleRemoveAccordion(accordionID)}
            ></DEButton>
          </MDBox>
        </AccordionSummary>
        <AccordionDetails>
          <MDBox>
            <DEButton
              stylingMode={'contained'}
              type={'default'}
              icon="add"
              text={'Add Combination'}
              onClick={addCombination}
            ></DEButton>
          </MDBox>
          {dataAccesses.get(accordionID)?.combinations?.size > 0 && (
            <MDBox py={2}>
              {[...dataAccesses.get(accordionID)?.combinations.entries()].map(
                ([key, value], index) => (
                  <div key={key + index} className="combination-item">
                    {!value.submitted && (
                      <span className="combination-item--close">
                        <IconButton
                          aria-label="cross"
                          onClick={() => handleSubmitCombination(key, true)}
                        >
                          <CloseIcon />
                        </IconButton>
                      </span>
                    )}
                    <MDTypography variant="h6">Combination ({index + 1})</MDTypography>
                    {value.submitted && (
                      <div className="combination-item--edit">
                        <div>
                          <span>Type:</span> {value.type?.label}
                        </div>
                        {value.formula && (
                          <div>
                            {value.type.value !== 'HFMS' && value.type.value !== 'market' && value.type.value !== 'master_employee' && value.type.value !== 'master_departments' && value.type.value !== 'master_client' && <span>Formula: </span>}
                            {Object.keys(MASTER_ID_MAP)
                              .map((id) => value.formula[id] && MASTER_ID_MAP[id])
                              .filter(Boolean)
                              .join('/')}
                          </div>
                        )}

                        <div>
                          <span>Transactions: </span>
                          {
                            value.type?.value === 'HFMS'
                            ? [...new Set(value.transactions[0]?.hfms)]?.length
                            : value.type?.value === 'market'
                                ? [...new Set(value.transactions[0]?.market)]?.length
                                : value.type?.value === 'master_employee'
                                  ? [...new Set(value.transactions[0]?.master_employee)]?.length
                                  : value.type?.value === 'master_departments'
                                    ? [...new Set(value.transactions[0]?.master_departments)]?.length
                                    : value.type?.value === 'master_client'
                                      ? [...new Set(value.transactions[0]?.master_client)]?.length
                                      : value.transactions?.length
                          }
                        </div>

                        <div className={'combination-item__edit-btn'}>
                          <DEButton
                            stylingMode={'text'}
                            type={'text'}
                            icon="edit"
                            text={''}
                            onClick={() => handleEditButtonClicked(key, value, accordionID)}
                          ></DEButton>
                        </div>
                      </div>
                    )}
                    <div style={{ display: value.submitted ? 'none' : 'block' }}>
                      <DataAccessCombination
                        combinationID={key}
                        editMode={value.dataAccessId}
                        accordionID={accordionID}
                        userDataAccess={userDataAccess}
                        combinationsData={combinationsData}
                        dataAccessFormulasData={dataAccessFormulasData}
                      />

                      <div className="combination-item__footer">
                        <DEButton
                          stylingMode={'outlined'}
                          type={'success'}
                          icon="check"
                          text={'Save'}
                          disabled={
                            !value.type?.value ||
                            !value.transactions ||
                            !Object.keys(value.transactions)?.length
                          }
                          onClick={() => handleSubmitCombination(key, true)}
                        ></DEButton>

                        <DEButton
                          stylingMode={'text'}
                          type={'danger'}
                          icon="trash"
                          text={'Delete'}
                          onClick={() => handleRemoveCombination(key)}
                        ></DEButton>
                      </div>
                    </div>
                  </div>
                )
              )}
            </MDBox>
          )}
        </AccordionDetails>
      </Accordion>
    </MDBox>
  );
};

export default DataAccessAccordion;
